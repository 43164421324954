import { appointmentUtilities } from '~modules/appointment/services';
import {
  RECOMMENDATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TELL_US_MORE_PAGE_NAME,
  VISIT_REASONS_PAGE_NAME,
} from '~types';

import { bookingRoutes } from './booking';
import { curatedFlowRoutes } from './curatedFlow';
import { stiRoutes } from './sti';
import { vaginitisRoutes } from './vaginitis';

import type { BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';

export const getCuratedDestination = (searchParams: BaseParamsWithoutRouting) => {
  const curatedFlow = appointmentUtilities.curatedFlow(searchParams.visit_reason);

  if (curatedFlow === 'sti') {
    return stiRoutes;
  }

  if (curatedFlow === 'vaginal_infection') {
    return vaginitisRoutes;
  }

  return { name: SCHEDULING_PAGE_NAME };
};

export const initialRoutes = {
  id: 'initial' as const,
  initial: () => ({ name: VISIT_REASONS_PAGE_NAME }),
  routes: {
    [VISIT_REASONS_PAGE_NAME]: {
      reset: true,
      on: {
        submit: () => ({ name: RECOMMENDATION_PAGE_NAME }),
      },
    },
    [RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: (_, searchParams) => getCuratedDestination(searchParams),
        curatedSubmit: () => curatedFlowRoutes,
        secondary: () => ({ name: SCHEDULING_PAGE_NAME, params: { showAll: 'true' } }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => ({ name: TELL_US_MORE_PAGE_NAME }),
        curatedSubmit: () => ({ name: RECOMMENDATION_PAGE_NAME, replace: true }),
      },
    },
    [TELL_US_MORE_PAGE_NAME]: {
      on: {
        submit: () => bookingRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
