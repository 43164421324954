import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useLocalSearchParams } from 'expo-router';

import { profilesApi } from '~modules/api';
import { MainForm, SelectInput } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { insuranceAtom } from '~modules/state';

import { INSURANCE_OPTIONS } from '../../config';
import { logAndCapture } from '../../logger';

import type { FormValues } from '@almond/ui';
import type { INSURANCE_PAGE_NAME, InsuranceFormValues } from '~types';

export const InsurancePage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof INSURANCE_PAGE_NAME>();
  const searchParams = useLocalSearchParams();

  const handleSubmit = useCallback(
    (values: FormValues<InsuranceFormValues> | null) => {
      const toCall = async (): Promise<void> => {
        if (!searchParams.profile_uuid) {
          return logAndCapture("`profile_uuid` is not specified, so can't create the insurance.");
        }

        const response = await profilesApi.createInsurance(searchParams.profile_uuid)({
          carrier: values ? values.carrier.value.value : 'noInsurance',
          optionalCarrierName: values ? values.carrier.key : undefined,
        });

        await dispatch('submit', {
          hasInsurance: !!values,
          isAccepted: response.isAccepted,
        });
      };

      doAsync(toCall);
    },
    [doAsync, searchParams.profile_uuid, dispatch]
  );

  return (
    <MainForm
      id="insurance"
      title={t('insurance.title')}
      submitButtonTitle={t('continue')}
      secondaryButtonTitle={t('insurance.noInsurance')}
      onSecondaryPress={() => handleSubmit(null)}
      atom={insuranceAtom}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      requiredFields={['carrier']}
      size="M"
    >
      <SelectInput
        name="carrier"
        options={INSURANCE_OPTIONS}
        keyTitle={t('insurance.addCarrierName')}
        testID="Insurance"
        keyTestID="Other"
        withTitle
      />
    </MainForm>
  );
};
