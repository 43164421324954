import React, { useRef } from 'react';
import { useWindowDimensions, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import {
  ActivityIndicator,
  ScrollView,
  SENTRY_UNMASK,
  Text,
  Title,
  useAdaptive,
  useBrowserTypeMap,
  useScrollToEnd,
  useShowScrollDownMessage,
  useTheme,
} from '@almond/ui';

import { NavigationBar } from '~modules/integration';
import { useNavigationBar } from '~modules/patient/hooks';
import { Background, ContactUs, LocalImage } from '~modules/ui';

import CenterContainer from './CenterContainer';
import { SizesMap } from './config';

import themedStyles from './styles';

import type { Size } from './config';
import type { ParseKeys } from '@almond/localization';
import type { Images } from '~assets';
import type { NavigationBarProps } from '~modules/integration';
import type { BackgroundProps } from '~modules/ui';
import type { RefObject } from 'react';
import type { ScrollView as NativeScrollView, StyleProp, ViewStyle } from 'react-native';

export type ContainerProps = Pick<BackgroundProps, 'accessibilityLabel' | 'noWave'> &
  Omit<NavigationBarProps, 'isAdminHost' | 'isLoading'> &
  React.PropsWithChildren<{
    id: `${ParseKeys}${string}`;
    title?: React.ReactNode;
    content?: React.ReactNode;
    isLoading?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    titleStyle?: StyleProp<ViewStyle>;
    contentBottomComponent?: React.ReactNode;
    size?: Size;
    scrollToBottomTrigger?: any;
    scrollViewRef?: RefObject<NativeScrollView>;
    showImage?: Extract<keyof Images, 'plant1' | 'plant2'>;
    statusBar?: React.ReactNode;
    contentContainerStyle?: StyleProp<ViewStyle>;
    backgroundDecoration?: React.ReactNode;
    navigationBarProps?: NavigationBarProps;
    backgroundProps?: BackgroundProps;
    hideContactUs?: boolean;
  }>;

export const Container: React.FC<ContainerProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const titleStyle = useAdaptive(styles.titleDesktop, styles.titleMobile);
  const showScrollDownMessage = useShowScrollDownMessage();
  const dimensions = useWindowDimensions();
  const imageStyles = [styles.image, dimensions.width < 750 && styles.imageTablet];
  const size = props.size || 'L';
  const { isMobile, isDesktop } = useBrowserTypeMap();
  const showImage = isDesktop && props.showImage;
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const { navigationBarProps = {}, backgroundProps = {} } = props;

  const { statusBar } = props;

  let scrollViewRef = useRef<NativeScrollView>(null);

  if (props.scrollViewRef) {
    scrollViewRef = props.scrollViewRef;
  }

  useScrollToEnd(scrollViewRef, props.scrollToBottomTrigger);

  const footerContent = (
    <View style={[styles.footer, isMobile && styles.footerContentMobile]}>
      {props.footer}
      {!props.hideContactUs && <ContactUs style={styles.contactUs} />}
    </View>
  );

  // Only show footer if it has content
  const footer = (showScrollDownMessage || isDesktop) && (
    <View
      style={[
        styles.horizontalPadding,
        styles.footer,
        styles.stickyFooter,
        !showImage && isDesktop && styles.stickyFooterBackground,
        !showImage && isMobile && styles.stickyFooterBackgroundMobile,
      ]}
    >
      {showScrollDownMessage && <Text size="xs">{t('scrollDown')}</Text>}
      {isDesktop && footerContent}
    </View>
  );

  return (
    <Background
      accessibilityLabel={props.accessibilityLabel}
      testID={props.id}
      noWave={props.noWave}
      {...backgroundProps}
    >
      {statusBar}
      <NavigationBar
        isLoading={isLoadingNavigationBar}
        bannerText={bannerText}
        shouldHideCloseButton={props.shouldHideCloseButton}
        onBack={props.onBack}
        {...navigationBarProps}
      />
      {props.backgroundDecoration}
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={[styles.scrollContainer, styles.horizontalPadding]}
        listenToNativeEvents
        testID="ContainerScrollView"
      >
        {showImage && <LocalImage source={showImage} style={imageStyles} />}
        <CenterContainer>
          {props.header}
          {!!props.title && (
            <View style={[titleStyle, props.titleStyle]} accessibilityLabel="TitleContainer">
              {typeof props.title === 'string' ? (
                <Title textAlign="center" testID={`${props.id}:${props.title}`} style={SENTRY_UNMASK}>
                  {props.title}
                </Title>
              ) : (
                props.title
              )}
            </View>
          )}

          {props.isLoading ? (
            <ActivityIndicator testID="FormActivityIndicator" accessibilityLabel="Loading..." />
          ) : (
            (props.content || props.children) && (
              <View style={[styles.contentContainer, { width: SizesMap[size] }, props.contentContainerStyle]}>
                {props.content || props.children}
                {props.contentBottomComponent && props.contentBottomComponent}
              </View>
            )
          )}
        </CenterContainer>
        {isMobile && footerContent}
        {footer}
      </ScrollView>
    </Background>
  );
};
