import React from 'react';

import { useTranslation } from '@almond/localization';
import { useSetRecoilState } from 'recoil';

import { CheckboxGroup, MainForm, useForm } from '~modules/forms';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { MAIN_SYMPTOMS_ITEMS } from '../../config';

import type { MAIN_SYMPTOMS_PAGE_NAME } from '~types';

export const MainSymptomsPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch, isRoutingActive } = useRouteNavigation<typeof MAIN_SYMPTOMS_PAGE_NAME>();
  const setQuestionnaireState = useSetRecoilState(questionnaireAtom);
  const formReturn = useForm({ atom: questionnaireAtom });

  const handleNone = async () => {
    const newMainSymptoms = MAIN_SYMPTOMS_ITEMS.filter(item => item.id === 'noneOfThese');

    setQuestionnaireState(prevValue => ({
      ...prevValue,
      mainSymptoms: newMainSymptoms,
    }));

    // If the user clicks "None", undo other checkboxes and check "None of these". The above statement
    // updates the state, but it doesn't update the form's rendered values. This next line forces
    // the form's rendered values to be updated.
    formReturn.setValue('mainSymptoms', newMainSymptoms);

    if (!isRoutingActive) {
      // Should never happen
      throw new Error('MainSymptoms: routing is not active');
    }

    await dispatch('submit');
  };

  return (
    <MainForm
      id="mainSymptoms"
      title={t('mainSymptoms.title')}
      submitButtonTitle={t('continue')}
      secondaryButtonTitle={t('mainSymptoms.skipTitle')}
      atom={questionnaireAtom}
      onSubmit={() => dispatch('submit')}
      onSecondaryPress={handleNone}
      requiredFields={['mainSymptoms']}
      formReturn={formReturn}
      size="M"
    >
      <CheckboxGroup name="mainSymptoms" items={MAIN_SYMPTOMS_ITEMS} noneId="noneOfThese" />
    </MainForm>
  );
};
