import { useRef, useState } from 'react';

import { useEvent } from '@almond/utils';

import { patientsApi } from '~modules/api';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';

import { SANDBOX_RESPONSE_ID, type typeformMessage } from '../config';

import type { INFECTION_QUESTIONNAIRE_PAGE_NAME } from '~types';
import type { z } from 'zod';

export const useCompleteTypeform = () => {
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof INFECTION_QUESTIONNAIRE_PAGE_NAME>();
  const [error, setError] = useState<Error | undefined>();
  const isRequestSuccessful = useRef<Record<string, boolean>>({});

  const completeTypeformIdempotent = useEvent(
    (outcome: z.infer<typeof typeformMessage>['outcome'], response: { responseId: string; formId: string }) => {
      // Loading indicator is handled by the widget. Until we
      // call dispatch(), the <iframe> will contain a loading
      // indicator.
      const toCall = async () => {
        setError(undefined);
        try {
          if (response.responseId !== SANDBOX_RESPONSE_ID && !isRequestSuccessful.current.submitTypeformResponse) {
            // If using MockWidget or in sandbox mode,
            // don't trigger the API request.
            await patientsApi.submitTypeformResponse({
              formId: response.formId,
              responseId: response.responseId as string,
            });

            isRequestSuccessful.current.submitTypeformResponse = true;
          }

          if ((outcome === 'reviewing' || outcome === 'urgentCare') && !isRequestSuccessful.current.sendSystemMessage) {
            await patientsApi.sendSystemMessage('infection_questionnaire_completion');

            isRequestSuccessful.current.sendSystemMessage = true;
          }

          dispatch(outcome);
        } catch (e) {
          setError(
            e instanceof Error
              ? e
              : new Error('There was a problem submitting the form. Please contact your care team for assistance.')
          );
        }
      };

      doAsync(toCall);
    }
  );

  return { completeTypeformIdempotent, completeTypeformError: error, isLoadingCompleteTypeform: isLoading };
};
