import { useCallback, useState } from 'react';

import { useToggle } from '@almond/utils';
import { useSetRecoilState } from 'recoil';

import { useRouteNavigation } from '~modules/routing';
import { appointmentAvailabilityMetadataAtom, appointmentParamsAtom } from '~modules/state';

import type { VisitOutcomeSchema } from '../../visitOutcomes';
import type { PhysicianTimeSlotsWithRecommendation } from './useSingleDayData';
import type { PhysicianFindOut, VisitLocationEnum } from '@almond/api-types';
import type { SCHEDULING_PAGE_NAME, Slot } from '~types';

export const useSelectTime = (
  visitOutcome: VisitOutcomeSchema | null,
  singleDayData: PhysicianTimeSlotsWithRecommendation[]
) => {
  const setAppointmentAvailabilityMetadataState = useSetRecoilState(appointmentAvailabilityMetadataAtom);
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);
  const { dispatch } = useRouteNavigation<typeof SCHEDULING_PAGE_NAME>();
  const [isDisclaimerModalVisible, toggleIsDisclaimerModalVisible] = useToggle(false);
  const [visitTime, setVisitTime] = useState<Slot | undefined>();
  const [physician, setPhysician] = useState<PhysicianFindOut | undefined>();
  const [appointmentType, setAppointmentType] = useState<string | undefined>();
  const [location, setLocation] = useState<VisitLocationEnum | undefined>();

  const onSubmit = useCallback(
    (vt?: Slot, p?: PhysicianFindOut, at?: string, l?: VisitLocationEnum) => {
      const toCall = async () => {
        setAppointmentParamsState(prevState => ({
          ...prevState,
          isTelehealth: (l ?? location) === 'Telehealth',
          visitTime: vt ?? visitTime,
          careTeamMember: (p ?? physician)?.uuid,
          type: at ?? appointmentType,
        }));
        setAppointmentAvailabilityMetadataState(prevState => ({
          ...prevState,
          recommendation: 'primary',
          numberOfPrimarySlotsAvailable: singleDayData
            .filter(d => d.recommendationType === 'primary')
            .flatMap(d => d.timeSlots).length,
        }));

        await dispatch('submit');
      };

      // Dispatch should be basically instant. If the above function ever
      // grows to have truly async code, be sure to show a loading indicator
      // to the user.
      toCall();
    },
    [
      appointmentType,
      dispatch,
      location,
      physician,
      setAppointmentAvailabilityMetadataState,
      setAppointmentParamsState,
      singleDayData,
      visitTime,
    ]
  );

  const onSelectTime = useCallback(
    (vt: Slot, p: PhysicianFindOut, at: string, l: VisitLocationEnum) => {
      if (!visitOutcome) return;

      setVisitTime(vt);
      setPhysician(p);
      setAppointmentType(at);
      setLocation(l);

      const isTelehealth = l === 'Telehealth';
      const shouldShowDisclaimerModal =
        // isTelehealth is different from the recommended one
        (visitOutcome.isTelehealth === false && isTelehealth) ||
        // or providerType is NP or PA and the recommended one is MD
        (visitOutcome.providerTypes?.includes('MD') && (p.providerType === 'NP' || p.providerType === 'PA'));

      if (shouldShowDisclaimerModal) {
        toggleIsDisclaimerModalVisible();

        return;
      }

      onSubmit(vt, p, at, l);
    },
    [visitOutcome, onSubmit, toggleIsDisclaimerModalVisible]
  );

  return { onSelectTime, onSubmit, isDisclaimerModalVisible, toggleIsDisclaimerModalVisible };
};
