import React, { useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '~modules/auth';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useSaveQuestionnaire } from '~modules/questionnaire/hooks';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { useGetOrCreatePatient } from '../../hooks';
import ActivityIndicator from './ActivityIndicator';

import type { CREATE_PATIENT_PAGE_NAME } from '~types';

export const CreatePatientPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CREATE_PATIENT_PAGE_NAME>();
  const { isAuthenticated } = useAuth0();
  const { doAsync } = useAsync();
  const getOrCreatePatient = useGetOrCreatePatient();
  const saveQuestionnaire = useSaveQuestionnaire();
  const questionnaireState = useRecoilValue(questionnaireAtom);
  const searchParams = useLocalSearchParams();
  const { setParams } = useRouter();
  const [isQuestionnaireSaved, setIsQuestionnaireSaved] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) return;
    let isMounted = true;

    const toCall = async () => {
      const patientUuid = await getOrCreatePatient();

      // If the user clicks the "back" button before the API request
      // is done, we don't want to complete this navigation because
      // the user won't be on this page anymore.
      if (isMounted) {
        setParams({ patient_uuid: patientUuid });
      }
    };

    doAsync(toCall);

    return () => {
      isMounted = false;
    };
  }, [doAsync, getOrCreatePatient, isAuthenticated, setParams]);

  useEffect(() => {
    if (!searchParams.patient_uuid || isQuestionnaireSaved) return;

    const toCall = async () => {
      await saveQuestionnaire(questionnaireState, 'visit_questions', 'visitQuestions');
      await dispatch('complete');
    };

    doAsync(toCall);
    setIsQuestionnaireSaved(true);
  }, [dispatch, doAsync, isQuestionnaireSaved, questionnaireState, saveQuestionnaire, searchParams.patient_uuid]);

  return <Container id="createPatient" title={t('createPatient.title')} content={<ActivityIndicator />} size="M" />;
};
