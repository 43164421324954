import { useCallback } from 'react';

import { patientsApi } from '~modules/api';

import type { FormValues } from '@almond/ui';
import type { ReferralFormValues } from '~types';

const useAddReferral = (): ((values: FormValues<ReferralFormValues>) => Promise<void>) => {
  return useCallback(async (values: FormValues<ReferralFormValues>) => {
    await patientsApi.addReferral({ referral: values.referral });
  }, []);
};

export default useAddReferral;
