import { areArraysEquivalent } from '@almond/utils';

import type { ProviderTypesEnum } from '@almond/api-types';

export const getProviderTypeString = (providerTypes: ProviderTypesEnum[]) => {
  if (areArraysEquivalent(providerTypes, ['NP', 'PA'])) {
    return `an NP or a PA`;
  }

  if (providerTypes.length === 1) {
    if (providerTypes[0] === 'MD') {
      return `an ObGyn`;
    }

    if (providerTypes[0] === 'MA') {
      return `a Medical Assistant`;
    }

    if (providerTypes[0] === 'PA') {
      return `a Physician Assistant`;
    }

    if (providerTypes[0] === 'NP') {
      return `a Nurse Practitioner`;
    }

    if (providerTypes[0] === 'HC') {
      return `a Health Coach`;
    }
  }

  return `a/an ${providerTypes.join(' or ')}`;
};
