import type { HistoryState } from '~types';

export const HISTORY_STATE_KEY = 'historyState';

export const DEFAULT_HISTORY_STATE: HistoryState = {
  pregnancies: [{}],
  perniciousHabits: [{ key: 'Drink' }, { key: 'Use tobacco' }, { key: 'Use marijuana' }, {}],
  healthConditions: '',
  medications: '',
  drugAllergies: '',
  doctorInfo: '',
  allergies: '',
  familyHistory: '',
  occupation: '',
  hospitalization: '',
  surgeries: '',
};
