import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, Link, List, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { PRODUCTS_PATH, profilesApi } from '~modules/api';
import { ErrorMessage } from '~modules/errors';
import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom } from '~modules/state';

import { calculateDiscountedTotalPrice, isPromotionCodeRepeating } from '../../services';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export type ContentProps = {
  onSubmitPress: () => void;
  isLoading: boolean;
};

export const Content: React.FC<ContentProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const searchParams = useLocalSearchParams();
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { data, isLoading, error, mutate } = useSWRImmutable(PRODUCTS_PATH, profilesApi.getProducts);
  const creditCardState = useRecoilValue(creditCardAtom);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (error || !data) {
    return <ErrorMessage error={error} onTryAgain={() => mutate()} isFull={false} style={styles.errorMessage} />;
  }

  const defaultAmount = data.price.amount;
  const discountedAmount = calculateDiscountedTotalPrice(data, [
    creditCardState.promotionCode,
    creditCardState.friendReferralCode,
  ]);
  const defaultPrice = formatPriceInCents(defaultAmount, true);
  const discountedPrice = formatPriceInCents(discountedAmount, true);
  const isPriceDiscounted = defaultAmount !== discountedAmount;
  const repeatingPriceCopy =
    isPriceDiscounted && !isPromotionCodeRepeating(creditCardState.promotionCode)
      ? t('pricingExplanation.content.firstYear')
      : t('pricingExplanation.content.perYear');

  return (
    <View style={[styles.container, isMobile && styles.fullWidth]}>
      <View style={[styles.subscription, isMobile && styles.fullWidth]}>
        <View style={styles.annualContainer}>
          <Text style={styles.annual} fontStyle="bold" size="xs" role="heading" aria-level={2}>
            {t('pricingExplanation.content.annual')}
          </Text>
        </View>
        <View style={styles.priceContainer}>
          {isPriceDiscounted && (
            <Text fontStyle="medium" size="xxxl" style={styles.oldPrice}>
              {defaultPrice}
            </Text>
          )}
          <Text fontStyle="medium" size="xxxl">
            {discountedPrice}
          </Text>
          <Text style={styles.perYear} size="xxl" fontStyle="medium">
            {repeatingPriceCopy}
          </Text>
        </View>
        <Text size="s" fontStyle="bold" style={styles.subscriptionItemsTitle}>
          {t('pricingExplanation.content.subscriptionItemsTitle')}
        </Text>
        <List.UnorderedList style={styles.subscriptionItemsList}>
          {t('pricingExplanation.content.subscriptionItems', { returnObjects: true }).map((item, index) => (
            <List.ListItem key={index}>
              <Text size="s">{item}</Text>
            </List.ListItem>
          ))}
        </List.UnorderedList>
        <Button
          type="primary"
          style={styles.button}
          onPress={props.onSubmitPress}
          isLoading={props.isLoading}
          isDisabled={props.isLoading}
          size="s"
          testID="Submit"
        >
          {t('continue')}
        </Button>
      </View>
      {searchParams.is_new_member === 'true' && (
        <Link
          url="#"
          onPress={e => {
            e.preventDefault();
            dispatch('learnMore');
          }}
          size="m"
          style={styles.returningMember}
        >
          {t('pricingExplanation.learnMore')}
        </Link>
      )}
    </View>
  );
};
