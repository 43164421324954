import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, useBrowserTypeMap, useTheme } from '@almond/ui';
import { cypressUtilities } from '@almond/utils';
import { useIsFocused } from '@react-navigation/native';
import { Widget } from '@typeform/embed-react';
import { useLocalSearchParams } from 'expo-router';

import { ErrorMessage } from '~modules/errors';
import { NavigationBar } from '~modules/integration';
import { useNavigationBar } from '~modules/patient/hooks';
import { useRouteNavigation } from '~modules/routing';
import { Background, ContactUs } from '~modules/ui';

import { useCompleteTypeform } from '../../hooks';
import { listenForMessage } from '../../services';
import { MockWidget } from './MockWidget';
import { SandboxBanner } from './SandboxBanner';

import themedStyles from './styles';

import type { typeformMessage } from '../../config';
import type { INFECTION_QUESTIONNAIRE_PAGE_NAME } from '~types';
import type { z } from 'zod';

const CURATED_BOOKING_TYPEFORM_ID = 'f9wO6H39';
const IS_PROD = process.env.EXPO_PUBLIC_ENV === 'prod';
const USE_MOCK_WIDGET = process.env.EXPO_PUBLIC_MOCK_TYPEFORM === 'true' || cypressUtilities.isCypressRunning();

const WidgetToRender = USE_MOCK_WIDGET ? MockWidget : Widget;

export const InfectionQuestionnairePage: React.FC = () => {
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const { dispatch } = useRouteNavigation<typeof INFECTION_QUESTIONNAIRE_PAGE_NAME>();
  const [sandboxMode, setSandboxMode] = useState(!IS_PROD);
  const searchParams = useLocalSearchParams();
  const isFocused = useIsFocused();
  const [response, setResponse] = useState<{ responseId: string; formId: string }>();
  const [outcome, setOutcome] = useState<z.infer<typeof typeformMessage>['outcome'] | undefined>();
  const [error, setError] = useState<Error | undefined>();

  const { completeTypeformIdempotent, completeTypeformError, isLoadingCompleteTypeform } = useCompleteTypeform();

  useEffect(() => {
    const unregister = listenForMessage((messageError, message) => {
      if (messageError) {
        setError(messageError);

        return;
      }

      setOutcome(message.outcome);
    });

    return () => {
      unregister();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!outcome || !response) return;

    completeTypeformIdempotent(outcome, response);
  }, [completeTypeformIdempotent, dispatch, outcome, response]);

  const hiddenValues = useMemo(
    () => ({
      patient_uuid: searchParams.patient_uuid,
      is_test: IS_PROD ? '' : 'true',
    }),
    [searchParams.patient_uuid]
  );

  if (!searchParams.patient_uuid || error || completeTypeformError || isLoadingCompleteTypeform) {
    return (
      <Background testID="infectionQuestionnairePage" noWave>
        <NavigationBar isLoading={isLoadingNavigationBar} bannerText={bannerText} />
        {isLoadingCompleteTypeform ? (
          <ActivityIndicator />
        ) : (
          <ErrorMessage
            error={(error || completeTypeformError) ?? new Error('Patient ID must be specified in URL to load form')}
            onTryAgain={
              completeTypeformError && outcome && response && (() => completeTypeformIdempotent(outcome, response))
            }
          />
        )}
      </Background>
    );
  }

  return (
    <Background testID="infectionQuestionnairePage" noWave>
      <NavigationBar isLoading={isLoadingNavigationBar} bannerText={bannerText} />
      {!IS_PROD && !USE_MOCK_WIDGET && <SandboxBanner isSandbox={sandboxMode} setIsSandbox={setSandboxMode} />}
      {isFocused && (
        // Re-mount when re-focusing. This causes the iframe content to reset
        // back to the initial URL when going back to this page with the Demi
        // back button.
        <WidgetToRender
          style={styles.widget}
          id={CURATED_BOOKING_TYPEFORM_ID}
          enableSandbox={sandboxMode}
          inlineOnMobile={true}
          redirectTarget="_self"
          hidden={hiddenValues}
          onSubmit={setResponse}
          iframeProps={{ style: 'border-top-left-radius: 0px; border-top-right-radius: 0px' }}
        />
      )}
      <View style={[styles.footer, isMobile && styles.footerContentMobile]}>
        <ContactUs style={styles.contactUs} />
      </View>
    </Background>
  );
};
