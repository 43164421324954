import { SchedulingPage } from '~modules/appointment';
import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';

export default withRouteBehavior(SchedulingPage, {
  allowDirectRouting: true,
  routingConfig: searchParams => {
    const existingRoutingConfigId = searchParams.get('routing_config_id');

    if (
      existingRoutingConfigId &&
      existingRoutingConfigId !== 'initialNewMember' &&
      existingRoutingConfigId !== 'initial'
    ) {
      return null;
    }

    // TODO detect invalid visit_reason and return null if invalid.
    // For now, doing this check inside SchedulingPage with useVerifyValidVisitReason()

    if (searchParams.get('is_new_member') !== 'false') {
      return configurations.initialNewMember;
    }

    return configurations.initial;
  },
  clearAuth: false,
  restoreAuth: true,
});
