import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { accountSetupAtom } from '~modules/state';

import { useAddReferral } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { REFERRAL_PAGE_NAME, ReferralFormValues } from '~types';

export const ReferralPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const addReferral = useAddReferral();
  const { dispatch } = useRouteNavigation<typeof REFERRAL_PAGE_NAME>();

  const handleSubmit = useCallback(
    (values: FormValues<ReferralFormValues>) => {
      const toCall = async (): Promise<void> => {
        await addReferral(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [addReferral, dispatch, doAsync]
  );

  return (
    <MainForm
      id="referral"
      title={t('referral.title')}
      submitButtonTitle={t('continue')}
      atom={accountSetupAtom}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      requiredFields={['referral']}
    >
      <ConnectedTextInput name="referral" placeholder={t('referral.placeholder')} testID="Referral" multiline />
    </MainForm>
  );
};
