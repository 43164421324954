import * as axiosBase from '../axiosBase';
import {
  ADDRESS_PATH,
  ATTACH_PATH,
  INSURANCE_CARD_BACK_PATH,
  INSURANCE_CARD_FRONT_PATH,
  INSURANCE_PATH,
  OTP_SESSIONS_PATH,
  PRODUCTS_PATH,
  PROFILES_PATH,
  PROMOTION_CODES,
  STRIPE_CUSTOMERS_PATH,
  VALIDATE_PATH,
} from './config';

import type { AxiosResponse } from 'axios';

export const createProfile = axiosBase.post(PROFILES_PATH);

export const createAddress = (uuid: string) => axiosBase.post(ADDRESS_PATH(uuid));

export const getProducts = async () => {
  const result = await axiosBase.get(PRODUCTS_PATH, { params: { lookup_keys: ['basic_annual_membership'] } });

  // In the near future we will be expanding this API call to
  // fetch multiple different products for the prospective member
  // to choose from. Making this quick-and-dirty in the meantime
  return result.products[0];
};

export const checkPromotionCode = async (code: string) => {
  const result = await axiosBase.get(PROMOTION_CODES, { params: { code: code.toLowerCase() } });

  return {
    ...result,
    code,
  };
};

export const getOrCreateStripeCustomer = (uuid: string) => axiosBase.post(STRIPE_CUSTOMERS_PATH(uuid));

export const createInsurance = (uuid: string) => axiosBase.post(INSURANCE_PATH(uuid));

export const addInsuranceId = (uuid: string) => axiosBase.put(INSURANCE_PATH(uuid));

export const uploadInsuranceCardFront = (uuid: string, fileUri: string): Promise<AxiosResponse> =>
  axiosBase.uploadFile(fileUri, INSURANCE_CARD_FRONT_PATH(uuid));

export const uploadInsuranceCardBack = (uuid: string, fileUri: string): Promise<AxiosResponse> =>
  axiosBase.uploadFile(fileUri, INSURANCE_CARD_BACK_PATH(uuid));

export const attachStripeCustomer = (uuid: string, paymentMethodId: string) =>
  axiosBase.post(ATTACH_PATH(uuid, paymentMethodId));

export const createOtpSession = (uuid: string) => axiosBase.post(OTP_SESSIONS_PATH(uuid));

export const validateOtpSession = (uuid: string) => axiosBase.post(VALIDATE_PATH(uuid));
