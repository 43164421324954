import { useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { useRecoilValue } from 'recoil';

import { creditCardAtom } from '~modules/state';

import { useCheckPromotionCode } from './useCheckPromotionCode';

import type { NotificationStatusBarProps } from '~modules/ui';

export const useReferralCodeNotificationStatus = (): NotificationStatusBarProps | undefined => {
  const { t } = useTranslation();
  const creditCardState = useRecoilValue(creditCardAtom);

  useCheckPromotionCode('friendReferralCode', 'friend_referral');

  return useMemo(() => {
    if (!creditCardState.friendReferralCode || creditCardState.friendReferralCode.state === 'init') {
      return;
    }

    const isSuccess = creditCardState.friendReferralCode?.state === 'success';
    const content = t(`promotionCodes.referral.${isSuccess ? 'success' : 'error'}`);

    return {
      type: isSuccess ? 'info' : 'error',
      content,
      isVisible: true,
    };
  }, [creditCardState.friendReferralCode, t]);
};
