import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
};
