import { appointmentParamsAtom } from '~modules/state';
import {
  ANTIBIOTICS_PAGE_NAME,
  FREQUENCY_PAGE_NAME,
  MEDICAL_CONDITIONS_PAGE_NAME,
  PREGNANT_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TREATMENT_RECOMMENDATION_PAGE_NAME,
  VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME,
} from '~types';

import { bookingRoutes } from './booking';
import { vaginitisRxRoutes } from './vaginitisRx';

import type { RoutingConfig, StackParamList } from '~types';

export const vaginitisRoutes = {
  id: 'vaginitis' as const,
  initialAsync: async getState => {
    const { isNewMemberRemote } = await getState(appointmentParamsAtom);

    if (isNewMemberRemote) {
      return { name: PREGNANT_PAGE_NAME };
    }

    return { name: VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME };
  },
  routes: {
    [VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PREGNANT_PAGE_NAME }),
      },
    },
    [PREGNANT_PAGE_NAME]: {
      on: {
        yes: async getState => {
          const { isNewMemberRemote } = await getState(appointmentParamsAtom);

          if (isNewMemberRemote) {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: TREATMENT_RECOMMENDATION_PAGE_NAME, params: { id: 'mdVisit' } };
        },
        no: () => ({ name: MEDICAL_CONDITIONS_PAGE_NAME }),
      },
    },
    [MEDICAL_CONDITIONS_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const { isNewMemberRemote } = await getState(appointmentParamsAtom);

          if (isNewMemberRemote) {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: TREATMENT_RECOMMENDATION_PAGE_NAME, params: { id: 'mdVisit' } };
        },
        noneOfThese: async getState => {
          const { isNewMemberRemote } = await getState(appointmentParamsAtom);

          if (isNewMemberRemote) {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: ANTIBIOTICS_PAGE_NAME };
        },
      },
    },
    [ANTIBIOTICS_PAGE_NAME]: {
      on: {
        yes: () => ({ name: TREATMENT_RECOMMENDATION_PAGE_NAME, params: { id: 'npVisit' } }),
        no: () => ({ name: FREQUENCY_PAGE_NAME }),
      },
    },
    [FREQUENCY_PAGE_NAME]: {
      on: {
        yes: () => ({ name: TREATMENT_RECOMMENDATION_PAGE_NAME, params: { id: 'npVisit' } }),
        no: () => vaginitisRxRoutes,
      },
    },
    [TREATMENT_RECOMMENDATION_PAGE_NAME]: {
      // Not for Rx or Testing, just for MD and NP/PA
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
        secondary: () => ({ name: SCHEDULING_PAGE_NAME }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => bookingRoutes,
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
