import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  sectionTitle: {
    marginTop: sizes.SPACING_M,
    marginBottom: sizes.SPACING_S,
  },
  loaderContainer: {
    rowGap: sizes.SPACING_S,
  },

  instructions: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
    marginBottom: sizes.SPACING_S,
    marginTop: sizes.SPACING_L,
  },
  returningMember: {
    textAlign: 'center',
    marginTop: 12,
    color: theme.secondaryTextDark,
  },
}));
