import React, { useCallback, useEffect, useState } from 'react';

import { ActivityIndicator, useTheme } from '@almond/ui';
import { useFocusEffect, useLocalSearchParams } from 'expo-router';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { integrationAtom, routingAlreadyInitializedAtom } from '~modules/state';
import { Background } from '~modules/ui';
import { UserSetter } from '~modules/user';

import { AuthPersistence } from './AuthPersistence';
import { RouteBehavior } from './RouteBehavior';

import themedStyles from './styles';

import type { AuthPersistenceProps } from './AuthPersistence';
import type { RouteBehaviorProps } from './RouteBehavior';
import type { ComponentType, PropsWithChildren } from 'react';

type IntegrationProps = {
  disableIdleModal?: boolean;
};

export const withRouteBehavior = (
  Component: ComponentType<PropsWithChildren> = () => null,
  routeBehaviorProps: RouteBehaviorProps & AuthPersistenceProps & IntegrationProps = {}
) => {
  const Wrapped = () => {
    const [alreadyInitted, setAlreadyInitted] = useRecoilState(routingAlreadyInitializedAtom);
    // Persist the on-load value of the recoil state if it's false, because we're
    // immediately setting it to true in the useEffect()
    const [localAlreadyInitted, setLocalAlreadyInitted] = useState(alreadyInitted);
    const setIntegrationState = useSetRecoilState(integrationAtom);

    useEffect(() => {
      if (!alreadyInitted) {
        setAlreadyInitted(true);
      }
    }, [alreadyInitted, setAlreadyInitted]);

    useFocusEffect(
      useCallback(() => {
        setIntegrationState(prevState => ({
          ...prevState,
          isIdleModalDisabled: routeBehaviorProps.disableIdleModal,
        }));

        return () => {
          // When the first page moves to the background, disable alreadyInittedRef
          setLocalAlreadyInitted(true);
        };
      }, [setIntegrationState])
    );

    const [styles] = useTheme(themedStyles);

    const loadingEle = routeBehaviorProps.loadingEle || (
      <Background style={styles.loader}>
        <ActivityIndicator accessibilityLabel="Loading" />
      </Background>
    );

    // When applying an initial routing_config_id, it can take a React
    // render or two to propagate to all components. If it hasn't
    // propagated, we can get an error if a dispatch() is called in an
    // immediate call to dispatch() within a useEffect(), like we have
    // on VisitReasonsPage for new members. So don't render the page until
    // the routingConfigId value starts to be returned from
    // useLocalSearchParams()
    const routingConfigId = useLocalSearchParams().routing_config_id;

    // TODO figure out a way to keep the same loading ele instance rendered
    // as we switch between each of these components rendering a loading ele.
    return (
      <AuthPersistence {...routeBehaviorProps} loadingEle={loadingEle} alreadyInitted={localAlreadyInitted}>
        <UserSetter loadingEle={loadingEle}>
          <RouteBehavior {...routeBehaviorProps} alreadyInitted={localAlreadyInitted}>
            {routingConfigId ? <Component /> : loadingEle}
          </RouteBehavior>
        </UserSetter>
      </AuthPersistence>
    );
  };

  Wrapped.displayName = `withRouteBehavior(${Component.displayName || Component.name || 'Component'})`;

  return Wrapped;
};
