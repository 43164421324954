import React from 'react';
import { View } from 'react-native';

import { BaseButton, MaterialIcon, sizes, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { RadioGroupItem } from '@almond/ui';

export type RadioButtonProps<T extends string> = {
  item: RadioGroupItem<T> & { description: string };
  isSelected: boolean;
  onPress: (item: RadioGroupItem<T> & { description: string }) => void;
};

const RadioButton = <T extends string>(props: RadioButtonProps<T>): React.JSX.Element => {
  const { item, isSelected, onPress } = props;
  const [styles] = useTheme(themedStyles);
  const textStyle = isSelected ? styles.selectedText : styles.text;

  return (
    <BaseButton
      onPress={() => onPress(item)}
      style={[styles.item, isSelected && styles.itemSelected]}
      testID={item.id}
      accessibilityRole="radio"
      aria-selected={isSelected}
    >
      {isSelected && <MaterialIcon source="check" size={sizes.SPACING_M} color="background" style={styles.icon} />}
      <View style={styles.contentContainer}>
        <Text fontStyle="bold" size="xl" style={[styles.title, textStyle]}>
          {item.title}
        </Text>
        {item.description && (
          <Text size="s" style={[styles.description, textStyle]}>
            {item.description}
          </Text>
        )}
      </View>
    </BaseButton>
  );
};

export default RadioButton;
