/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */
import { I18n } from '@almond/localization';

import type { ProviderTypesEnum } from '@almond/api-types';

type ValueGetterParams = {
  isNewMember?: boolean;
  infectionOutcome?: boolean;
  hasSkinSymptoms?: boolean;
  showAll?: boolean;
  notSeenInLastYear?: boolean;
  questionnaire?: {
    pregnant?: boolean;
    medicalConditions?: boolean;
    antibiotics?: boolean;
    vaginalInfectionFrequency?: boolean;
  };
};

type ValueGetter<V> = (params: ValueGetterParams) => V | null;

type CuratedCopy = {
  title: string;
  description: string;
};

export type VisitOutcomeSchema = {
  // Who can schedule?
  adminOnly: boolean;
  // Default Rec / is_telehealth
  isTelehealth: boolean | null;
  // Default Rec / provider_types
  providerTypes: ProviderTypesEnum[] | null;
  // If this is for a lab booking. The Visit Reasons Modal text will be different if this is true
  isLab?: boolean;
  // Default Rec Copy / provider_types
  copy: string | null;
  // Care Rec Copy - Est Member
  curatedCopy?: CuratedCopy;
  // Duration
  duration: number;
  // Opt-out (All)
  // P2 Acuteness level
  inSentence: string | null;
};

type VisitOutcomesSchema = Record<string, ValueGetter<VisitOutcomeSchema>>;

export type VisitOutcome = VisitOutcomeSchema;

export type VisitOutcomes = Record<string, VisitOutcome>;

// eslint-disable-next-line max-statements
export const getInfectionValue = ({
  isNewMember,
  notSeenInLastYear,
  questionnaire,
}: ValueGetterParams): Pick<
  VisitOutcome,
  'copy' | 'curatedCopy' | 'isTelehealth' | 'providerTypes' | 'isLab' | 'inSentence'
> => {
  const inSentence = 'a Vaginal Infection';

  // Note: Current order doesn't match the order from the remote table, because we need to sort the conditions out.

  // Complex
  if (isNewMember && (questionnaire?.pregnant || questionnaire?.medicalConditions)) {
    return {
      isTelehealth: true,
      providerTypes: ['MD'],
      copy: 'We recommend you see an ObGyn over video',
      inSentence,
    };
  }

  // New/Non-Complex
  if (isNewMember) {
    return {
      isTelehealth: true,
      providerTypes: ['MD'],
      copy: 'We recommend you see an ObGyn over video',
      inSentence,
    };
  }

  // Returning patient / Non-Complex/Antibiotic or Frequency
  if (questionnaire?.antibiotics || questionnaire?.vaginalInfectionFrequency) {
    return {
      isTelehealth: true,
      providerTypes: ['NP', 'PA'],
      copy: 'We recommend a video visit with an NP or a PA',
      curatedCopy: {
        title: I18n.t('treatmentRecommendation.message.npVisit.title'),
        description:
          // eslint-disable-next-line max-len
          'Based on your answers we recommend you see an Nurse Practitioner via video so you can be seen swiftly. They can take a deeper look and recommend the right treatment for a vaginal infection.',
      },
      inSentence,
    };
  }

  // Returning patient / Complex
  if (questionnaire?.pregnant || questionnaire?.medicalConditions) {
    return {
      isTelehealth: true,
      providerTypes: ['MD'],
      copy: 'We recommend you see an ObGyn over video',
      curatedCopy: {
        title: I18n.t('treatmentRecommendation.message.mdVisit.title'),
        description:
          // eslint-disable-next-line max-len
          'Based on your answers we recommend you see an ObGyn. They can take a deeper look and recommend the right treatment for a vaginal infection.',
      },
      inSentence,
    };
  }

  // Returning patient / > 1 year last seen
  if (notSeenInLastYear) {
    return {
      isTelehealth: true,
      providerTypes: ['NP', 'PA'],
      copy: 'We recommend a video visit with an NP or a PA',
      curatedCopy: {
        title: I18n.t('treatmentRecommendation.message.npVisit.title'),
        description:
          // eslint-disable-next-line max-len
          'Based on your answers we recommend you see an Nurse Practitioner via video so you can be seen swiftly. They can take a deeper look and recommend the right treatment for a vaginal infection.',
      },
      inSentence,
    };
  }

  // Returning patient / Self Serve Testing
  return {
    isTelehealth: false,
    providerTypes: ['MA'],
    isLab: true,
    copy: 'Coming to the office for labwork is required for this visit',
    curatedCopy: {
      title: 'Come in for Self-Serve Testing',
      description:
        // eslint-disable-next-line max-len
        "Based on your symptoms we recommend you come to our office to get tested for a vaginal infection. It only takes a simple swab, you can do it for yourself. While you're here you can also get an STI test if you'd like.",
    },
    inSentence,
  };
};

export const getStiValue = ({
  isNewMember,
  hasSkinSymptoms,
}: ValueGetterParams): Pick<VisitOutcome, 'copy' | 'curatedCopy' | 'isTelehealth' | 'providerTypes' | 'inSentence'> => {
  const inSentence = 'an STI Check & Treatment';

  // Skin Symptoms
  if (isNewMember && hasSkinSymptoms) {
    return {
      isTelehealth: false,
      copy: 'We recommend you see an ObGyn in the office',
      providerTypes: ['MD'],
      inSentence,
    };
  }

  // New / No Skin Symptoms
  if (isNewMember && !hasSkinSymptoms) {
    return {
      isTelehealth: true,
      providerTypes: ['MD'],
      copy: 'We recommend you see an ObGyn over video',
      inSentence,
    };
  }

  // Skin Symptoms
  if (hasSkinSymptoms) {
    return {
      isTelehealth: false,
      copy: 'We recommend you see an NP or a PA in the office',
      curatedCopy: {
        title: 'Skin Exam + STI Testing',
        description:
          // eslint-disable-next-line max-len
          'Based on your skin symptoms, we’d like you to come in for an exam, ideally ASAP. Skin symptoms offer important information for testing and sometimes go away quickly. While you’re here your provider will help you decide which STIs to test for.',
      },
      providerTypes: ['NP', 'PA'],
      inSentence,
    };
  }

  // Est / No Skin Symptoms
  return {
    isTelehealth: true,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend a video visit with an NP or a PA',
    curatedCopy: {
      title: I18n.t('treatmentRecommendation.message.npVisit.title'),
      description:
        // eslint-disable-next-line max-len
        'Based on your answers we recommend you see an Nurse Practitioner via video so you can be seen swiftly. They can take a deeper look and recommend the right treatment for any possible STI.',
    },
    inSentence,
  };
};

const getCuratedFlowValue = ({ isNewMember, infectionOutcome, showAll }: ValueGetterParams) => {
  let isTelehealth = !!(isNewMember || showAll);

  if (infectionOutcome) {
    isTelehealth = false;
  }

  return {
    isTelehealth,
    copy: `We recommend ${isTelehealth ? 'a video' : 'an office'} visit with an NP or a PA`,
  };
};

const pcosMedicalType = (duration: 30 | 60, providerTypes: ProviderTypesEnum[]) => ({
  adminOnly: true,
  isTelehealth: null,
  providerTypes,
  copy: 'Book your PCOS visit',
  duration,
  inSentence: 'PCOS medical visit',
});

const pcosHealthCoachType = {
  adminOnly: true,
  isTelehealth: true,
  providerTypes: ['HC'] as const satisfies string[],
  copy: 'You will see a Health Coach over video',
  duration: 30,
  inSentence: 'PCOS health coach visit',
};

// null means "any" - no value restrictions.
// To represent "no secondary option", the secondary option must
// match the primary option - in that case, the user won't be
// shown a secondary value.
export const visitOutcomesSchema: VisitOutcomesSchema = {
  // Annual Well Woman
  yearly_exam: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: null,
    copy: 'We recommend you come to the office for this visit',
    duration: 30,
    inSentence: 'an Annual Well Woman Exam',
  }),
  // Birth Control Counselling
  birth_control: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // New Member Infection AND new Curated Flow for returning members
  general_infection: ({ isNewMember, infectionOutcome, showAll }) => {
    const { isTelehealth, copy } = getCuratedFlowValue({ isNewMember, infectionOutcome, showAll });

    return {
      adminOnly: false,
      isTelehealth,
      providerTypes: ['NP', 'PA'],
      copy,
      duration: 30,
      inSentence: null,
    };
  },
  // New Member Hormonal Health
  hormonal_health_or_fertility: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: 30,
    inSentence: null,
  }),
  // Vaginal Infection
  infection: params => ({
    adminOnly: false,
    isTelehealth: getInfectionValue(params).isTelehealth,
    providerTypes: getInfectionValue(params).providerTypes,
    copy: getInfectionValue(params).copy,
    isLab: getInfectionValue(params).isLab,
    curatedCopy: getInfectionValue(params).curatedCopy,
    duration: params.isNewMember ? 30 : 15,
    inSentence: getInfectionValue(params).inSentence,
  }),
  // Vulvar Skin Symptoms
  vulvar_skin_symptoms: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: isNewMember ? ['MD'] : ['NP', 'PA'],
    copy: isNewMember
      ? 'We recommend you see an ObGyn in the office'
      : 'We recommend you see an NP or a PA in the office',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // STI Check & Treatment
  sti_check: params => ({
    adminOnly: false,
    isTelehealth: getStiValue(params).isTelehealth,
    providerTypes: getStiValue(params).providerTypes,
    copy: getStiValue(params).copy,
    curatedCopy: getStiValue(params).curatedCopy,
    duration: params.isNewMember ? 30 : 15,
    inSentence: getStiValue(params).inSentence,
  }),
  // Period Management
  period_management: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: isNewMember ? ['MD'] : ['NP', 'PA'],
    copy: isNewMember ? 'We recommend a video visit with an ObGyn' : 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // Perimenopause & Menopause
  perimenopause_or_menopause: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: 30,
    inSentence: 'a Perimenopause or Menopause visit',
  }),
  // TODO: It's marked as `General Visit` in the table, is it correct?
  // General Visit
  other: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: isNewMember ? null : ['NP', 'PA'],
    copy: isNewMember ? 'We recommend a video visit' : 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: 'a General Visit',
  }),
  // Pregnancy Termination
  pregnancy_termination: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend you see an NP or a PA in the office',
    duration: 30,
    inSentence: 'a Pregnancy Termination',
  }),
  // Preconception & Fertility
  pregnancy_preconception: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // New Pregnancy Intro - video visit
  pregnancy_intro_call: () => ({
    adminOnly: true,
    isTelehealth: true,
    providerTypes: null,
    copy: null,
    duration: 15,
    inSentence: 'a New Pregnancy Intro - video visit',
  }),
  // Early Preg. Counseling < 8 wks
  early_pregnancy_counseling: () => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend a video visit with an NP or a PA',
    duration: 30,
    inSentence: null,
  }),
  // Preg. Confirmation (8-11 wks)
  pregnancy_confirmation: ({ isNewMember }) => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn in the office',
    duration: isNewMember ? 60 : 30,
    inSentence: null,
  }),
  // First Maternity (10+ wks)
  first_maternity: ({ isNewMember }) => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn in the office',
    duration: isNewMember ? 30 : 60,
    inSentence: 'a First Maternity visit',
  }),
  // Follow-Up Maternity
  maternity_follow_up: ({ isNewMember }) => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: isNewMember ? null : ['MD'],
    copy: isNewMember ? null : 'We recommend you see an ObGyn in the office',
    duration: isNewMember ? 30 : 15,
    inSentence: 'a Maternity Follow Up',
  }),
  assisted_lab: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MA'],
    isLab: true,
    copy: 'Coming to the office for labwork is required for this visit',
    duration: 15,
    inSentence: 'labwork',
  }),
  procedure: () => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: null,
    copy: 'An office visit is required for this visit',
    duration: 30,
    inSentence: 'a Procedure',
  }),
  pcos_care: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend you see an NP or PA over video',
    duration: 30,
    inSentence: 'PCOS Care',
  }),
  pcos_medical_visit_1: () => pcosMedicalType(60, ['NP', 'PA']),
  pcos_medical_visit_2: () => pcosMedicalType(60, ['MD']),
  pcos_medical_visit_3: () => pcosMedicalType(30, ['NP', 'PA']),
  pcos_medical_visit_4: () => pcosMedicalType(30, ['MD']),
  pcos_health_coach_visit_1: () => pcosHealthCoachType,
  pcos_health_coach_visit_2: () => pcosHealthCoachType,
  pcos_health_coach_visit_3: () => pcosHealthCoachType,
  pcos_health_coach_visit_4: () => pcosHealthCoachType,
};
