import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  item: {
    userSelect: 'none',
    backgroundColor: theme.background,
    marginVertical: sizes.SPACING_S,
    borderRadius: sizes.SPACING_S,
    ...getShadow(),
  },
  itemSelected: {
    backgroundColor: theme.primary,
  },
  contentContainer: {
    flex: 1,
    paddingVertical: sizes.SPACING_M,
    paddingHorizontal: sizes.SPACING_XL,
  },
  title: {
    marginTop: sizes.SPACING_S,
    textAlign: 'center',
  },
  description: {
    marginTop: 12,
  },
  text: {
    color: theme.darkText,
  },
  selectedText: {
    color: theme.lightText,
  },
  items: {
    marginTop: sizes.SPACING_M,
  },
  link: {
    textAlign: 'center',
    marginTop: sizes.SPACING_M,
    marginBottom: -2,
  },
  linkSelected: {
    color: theme.lightText,
  },
  icon: {
    position: 'absolute',
    top: sizes.SPACING_S,
    right: sizes.SPACING_S,
  },
  checkboxGroupTitle: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
    marginBottom: sizes.SPACING_S,
    marginTop: sizes.SPACING_L,
  },
}));
