import type { BinaryEnum, MainSymptomEnum } from '../api';
import type {
  DiagnosisCodesEnum,
  MedicalConditionsEnum,
  OutcomesEnum,
  TreatmentPreferencesEnum,
  VaginalInfectionSymptomsEnum,
} from '@almond/api-types';
import type { CheckboxGroupItem, RadioGroupItem } from '@almond/ui';

export type QuestionnaireState = {
  uuid?: string;
  mainSymptoms?: CheckboxGroupItem<MainSymptomEnum>[];
  symptoms?: string;
  pregnant?: RadioGroupItem<BinaryEnum>;
  visitQuestions?: string;
  vaginalInfectionSymptoms?: CheckboxGroupItem<VaginalInfectionSymptomsEnum>[];
  medicalConditions?: CheckboxGroupItem<MedicalConditionsEnum>[];
  antibiotics?: RadioGroupItem<BinaryEnum>;
  vaginalInfectionFrequency?: RadioGroupItem<BinaryEnum>;
  outcome?: OutcomesEnum;
  treatmentPreference?: TreatmentPreferencesEnum[];
  medications?: string;
  drugAllergies?: string;
  diagnosis?: DiagnosisCodesEnum;
};
