import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { Button, PulseProvider } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { LAB_ORDERS_PATH, patientsApi, useFocusedSWR } from '~modules/api';
import { ErrorMessage } from '~modules/errors';
import { useRedirectBackToArtemis } from '~modules/integration';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';
import { InfoMessage } from '~modules/ui';

import { Item, LoadingItem } from './Item';

import type { LAB_ORDERS_PAGE_NAME } from '~types';

export const LabOrdersPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof LAB_ORDERS_PAGE_NAME>();
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);
  const redirectBackToArtemis = useRedirectBackToArtemis();

  const { isLoading, data, error, mutate } = useFocusedSWR(LAB_ORDERS_PATH, patientsApi.getLabOrders, {
    revalidateOnFocus: false,
  });

  const handleSubmit = useCallback(() => {
    setAppointmentParamsState(prevState => ({ ...prevState, labOrders: data?.labOrders.map(o => o.uuid) }));
    dispatch('submit');
  }, [data?.labOrders, dispatch, setAppointmentParamsState]);

  const isNoneVisible = !isLoading && !error && data && !data?.labOrders?.length;

  let content: React.ReactNode = null;
  let footer: React.ReactNode = null;

  if (isNoneVisible) {
    content = <InfoMessage title={t('labOrders.noneSubtitle')} description={t('labOrders.noneDescription')} />;
  } else if (isLoading) {
    content = <LoadingItem />;
  } else if (data?.labOrders?.length) {
    content = data.labOrders.map(item => {
      return <Item key={item.uuid} item={item} />;
    });
  } else {
    content = <ErrorMessage error={error} onTryAgain={() => mutate()} isFull={false} />;
  }

  if (isNoneVisible) {
    footer = (
      <Button type="primary" onPress={() => redirectBackToArtemis()} testID="MessageCareTeam">
        {t('labOrders.noneMessageCareTeam')}
      </Button>
    );
  } else if (!(error && !isLoading)) {
    footer = (
      <>
        <Button type="primary" onPress={handleSubmit} isDisabled={isLoading} testID="Submit">
          {t('labOrders.submit')}
        </Button>
        <Button
          type="secondary"
          onPress={() => redirectBackToArtemis()}
          isDisabled={isLoading}
          testID="MessageCareTeam"
        >
          {t('labOrders.messageCareTeam')}
        </Button>
      </>
    );
  }

  return (
    <PulseProvider duration={1500}>
      <Container
        id="labOrders"
        title={t(isNoneVisible ? 'labOrders.noneTitle' : 'labOrders.title', { count: data?.labOrders?.length || 1 })}
        size="L"
        footer={footer}
      >
        {content}
      </Container>
    </PulseProvider>
  );
};
