import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';

import { MainForm, RadioGroup } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { BINARY_ITEMS } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { PREGNANT_PAGE_NAME, QuestionnaireState } from '~types';

export const PregnantPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const saveQuestionnaire = useSaveQuestionnaire();
  const { dispatch } = useRouteNavigation<typeof PREGNANT_PAGE_NAME>();

  const handleSubmit = useCallback(
    (values: FormValues<QuestionnaireState>) => {
      const toCall = async (): Promise<void> => {
        if (!values.pregnant) return;

        await saveQuestionnaire(values, 'pregnant', 'pregnant');
        await dispatch(values.pregnant.id);
      };

      doAsync(toCall);
    },
    [doAsync, saveQuestionnaire, dispatch]
  );

  return (
    <MainForm
      id="pregnant"
      title={t('pregnant.title')}
      atom={questionnaireAtom}
      onSubmit={handleSubmit}
      submitButtonTitle={t('continue')}
      requiredFields={['pregnant']}
      isLoading={isLoading}
      size="S"
    >
      <RadioGroup items={BINARY_ITEMS} name="pregnant" />
    </MainForm>
  );
};
