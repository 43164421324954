import type { QuestionnaireState } from '~types';

export const QUESTIONNAIRE_STATE_KEY = 'questionnaireState';

export const DEFAULT_QUESTIONNAIRE_STATE: QuestionnaireState = {
  mainSymptoms: [],
  visitQuestions: '',
  vaginalInfectionSymptoms: [],
  medicalConditions: [],
};
