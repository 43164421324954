import { useCallback, useMemo, useState } from 'react';

import { HOME_SITE_HREF } from '@almond/ui';

import useAuth0 from '~modules/auth/hooks/useAuth0';

import { useRedirectBackToArtemis } from '../../hooks';

export const useCloseButton = () => {
  const { isAuthenticated } = useAuth0();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const text: 'backToDashboard' | 'backToWebsite' = (() => {
    if (isAuthenticated) return 'backToDashboard';

    return 'backToWebsite';
  })();
  const redirectBackToArtemis = useRedirectBackToArtemis();

  const toggleModal = useCallback(() => {
    setIsModalVisible(prevState => !prevState);
  }, []);

  const backTo = useCallback(() => {
    if (isAuthenticated) {
      redirectBackToArtemis();

      return;
    }

    window.location.assign(HOME_SITE_HREF);
  }, [isAuthenticated, redirectBackToArtemis]);

  const onPress = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const onLeavePress = useCallback(() => {
    toggleModal();
    backTo();
  }, [backTo, toggleModal]);

  return useMemo(
    () => ({ text, onPress, onLeavePress, toggleModal, isModalVisible }),
    [isModalVisible, onLeavePress, onPress, text, toggleModal]
  );
};
