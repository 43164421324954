import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  list: {
    gap: sizes.SPACING_M,
    paddingHorizontal: sizes.SPACING_XXL,
    paddingVertical: sizes.SPACING_XXL,
  },
  listItem: {
    flexDirection: 'row',
    gap: sizes.SPACING_M,
  },
  disclaimer: {
    color: theme.secondaryTextDark,
    marginTop: sizes.SPACING_M,
    marginBottom: -sizes.SPACING_M,
  },
}));
