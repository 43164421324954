import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';

import { MainForm, RadioGroup } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { BINARY_ITEMS } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { ANTIBIOTICS_PAGE_NAME, QuestionnaireState } from '~types';

export const AntibioticsPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const saveQuestionnaire = useSaveQuestionnaire();
  const { dispatch } = useRouteNavigation<typeof ANTIBIOTICS_PAGE_NAME>();

  const handleSubmit = useCallback(
    (values: FormValues<QuestionnaireState>) => {
      const toCall = async (): Promise<void> => {
        if (!values.antibiotics) return;

        await saveQuestionnaire(values, 'antibiotics', 'antibiotics');
        await dispatch(values.antibiotics.id);
      };

      doAsync(toCall);
    },
    [doAsync, saveQuestionnaire, dispatch]
  );

  return (
    <MainForm
      id="antibiotics"
      title={t('antibiotics.title')}
      atom={questionnaireAtom}
      onSubmit={handleSubmit}
      submitButtonTitle={t('continue')}
      requiredFields={['antibiotics']}
      isLoading={isLoading}
      size="S"
    >
      <RadioGroup items={BINARY_ITEMS} name="antibiotics" />
    </MainForm>
  );
};
