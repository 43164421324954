import React from 'react';

import { useTranslation } from '@almond/localization';
import { RadioGroup } from '@almond/ui';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';

import { TESTING_ITEMS } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';
import RadioButton from './RadioButton';

import type { TESTING_PAGE_NAME } from '~types';

export const TestingPage: React.FC = () => {
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const saveQuestionnaire = useSaveQuestionnaire();
  const { dispatch } = useRouteNavigation<typeof TESTING_PAGE_NAME>();

  const handleSubmit = () => {
    const toCall = async () => {
      await saveQuestionnaire({ outcome: 'testing' }, 'outcome', 'outcome');
      await dispatch('submit');
    };

    doAsync(toCall);
  };

  return (
    <MainForm
      id="testing"
      title={t('testing.title')}
      size="M"
      isLoading={isLoading}
      submitButtonTitle={t('testing.submitTitle')}
      onSubmit={handleSubmit}
    >
      <RadioGroup
        items={TESTING_ITEMS}
        RadioButtonComponent={RadioButton}
        onSelect={() => undefined}
        selectedItem={TESTING_ITEMS.find(item => item.id === 'self_serve_testing')}
      />
    </MainForm>
  );
};
