import React from 'react';

import { useTranslation } from '@almond/localization';

import { CheckboxGroup, MainForm, useForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { VAGINAL_INFECTION_SYMPTOMS_ITEMS } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { QuestionnaireState, VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME } from '~types';

export const VaginalInfectionSymptomsPage: React.FC = () => {
  const { t } = useTranslation();
  const formReturn = useForm({ atom: questionnaireAtom });
  const saveQuestionnaire = useSaveQuestionnaire();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME>();

  const handleSubmit = (values: FormValues<QuestionnaireState>): void => {
    const toCall = async (): Promise<void> => {
      if (!values.vaginalInfectionSymptoms) return;

      await saveQuestionnaire(values, 'vaginal_infection_symptoms', 'vaginalInfectionSymptoms');
      await dispatch('submit');
    };

    doAsync(toCall);
  };

  return (
    <MainForm
      id="vaginalInfectionSymptoms"
      title={t('vaginalInfectionSymptoms.title')}
      submitButtonTitle={t('continue')}
      atom={questionnaireAtom}
      requiredFields={['vaginalInfectionSymptoms']}
      formReturn={formReturn}
      size="M"
      onSubmit={handleSubmit}
      isLoading={isLoading}
    >
      <CheckboxGroup name="vaginalInfectionSymptoms" items={VAGINAL_INFECTION_SYMPTOMS_ITEMS} />
    </MainForm>
  );
};
