import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';

import { useCanSkipLogout } from '~modules/routing';
import { authAtom } from '~modules/state';

import { log } from '../logger';

// This needs to NOT depend on our useAuth0() wrapper, or on the `user` object at all,
// because this needs to be resolved *before* we make the call for the user object
const useClearCurrentSession = (clearAuth: boolean, alreadyInitted: boolean): boolean => {
  const { isLoading, isAuthenticated, logout } = useAuth0();
  const authState = useRecoilValue(authAtom);
  const canSkipLogout = useCanSkipLogout();
  const [isClearing, setIsClearing] = useState(
    alreadyInitted ? false : !canSkipLogout && clearAuth && !authState.isRedirected
  );

  useEffect(() => {
    if (alreadyInitted || !clearAuth || canSkipLogout || authState.isRedirected) {
      return;
    }

    if (isLoading) return;

    if (isAuthenticated) {
      log(
        `Auth0 is authenticated, so clearing the current session and replacing the URL with ${window.location.origin}`
      );

      logout({ logoutParams: { returnTo: window.location.origin } });
    }

    setIsClearing(false);
  }, [alreadyInitted, authState.isRedirected, canSkipLogout, clearAuth, isAuthenticated, isLoading, logout]);

  return isClearing;
};

export default useClearCurrentSession;
