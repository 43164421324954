import React from 'react';

import { useController, useFormContext, useFormState } from '@almond/ui';
import { get } from 'react-hook-form';

import { ImageInput } from '~modules/ui';

import type { ImageInputProps } from '~modules/ui';

export type ConnectedImageInputProps = Omit<ImageInputProps, 'onSelect'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedImageInput: React.FC<ConnectedImageInputProps> = props => {
  const { name, onSubmitEditing, isDisabled, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, ...restField } = field;
  const formState = useFormState();
  const isInputDisabled = isDisabled || formState.isSubmitting || formContext.isDisabled || formContext.isLoading;

  return (
    <ImageInput
      {...restField}
      {...restProps}
      error={get(formState.errors, name)}
      isDisabled={isInputDisabled}
      onSelect={uri => onChange(uri)}
    />
  );
};

export default ConnectedImageInput;
