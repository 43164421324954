import { appointmentParamsAtom, questionnaireAtom } from '~modules/state';
import { MAIN_SYMPTOMS_PAGE_NAME, SCHEDULING_PAGE_NAME, TREATMENT_RECOMMENDATION_PAGE_NAME } from '~types';

import { bookingRoutes } from './booking';

import type { RoutingConfig, StackParamList } from '~types';

export const stiRoutes = {
  id: 'sti' as const,
  initial: () => ({ name: MAIN_SYMPTOMS_PAGE_NAME }),
  routes: {
    [MAIN_SYMPTOMS_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const { isNewMemberRemote } = await getState(appointmentParamsAtom);
          const questionnaireState = await getState(questionnaireAtom);
          const id = questionnaireState.mainSymptoms?.some(symptom => symptom.id === 'ulb')
            ? 'skinSymptoms'
            : 'noSkinSymptoms';

          if (isNewMemberRemote) {
            return { name: SCHEDULING_PAGE_NAME, params: { showAll: undefined } };
          }

          return {
            name: TREATMENT_RECOMMENDATION_PAGE_NAME,
            params: { id },
          };
        },
      },
    },
    [TREATMENT_RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
        secondary: () => ({ name: SCHEDULING_PAGE_NAME }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => bookingRoutes,
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
