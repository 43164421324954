import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { VISIT_REASONS_PATH, visitReasonsApi } from '~modules/api';
import { useAuth0 } from '~modules/auth';
import { appointmentParamsAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

import type { GetVisitReasonsParams } from '@almond/api-types';

export const useVisitReasons = () => {
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isAdmin = useIsAdmin();
  const key: [GetVisitReasonsParams, string] | null = useMemo(() => {
    if (isAuthenticating || (!isAuthenticated && !isNewMemberRemote)) {
      // Prevent the network request from happening
      return null;
    }

    return [
      // Pass in directly what goes out to the API request
      {
        member_types: isNewMemberRemote ? ['new'] : ['established'],
        bookable_by_roles: isAdmin ? ['internal', 'patient'] : ['patient'],
        is_active: true,
      },
      VISIT_REASONS_PATH,
    ];
  }, [isAdmin, isAuthenticated, isAuthenticating, isNewMemberRemote]);
  const { data, isLoading, error, mutate } = useSWRImmutable(key, async ([params]) => {
    const result = await visitReasonsApi.getVisitReasons(params);

    return result.visitReasons;
  });

  // We want to tell if a visit reason is associated with a PCOS membership.
  // Reference the category, since "PCOS Care" in "general" category should not be included
  const visitReasons = useMemo(
    () => data?.map(d => ({ ...d, isPcos: d.category.toLowerCase().includes('pcos') })),
    [data]
  );

  return {
    visitReasons,
    isLoadingVisitReasons: isLoading,
    visitReasonsError: error,
    mutateVisitReasons: mutate,
  };
};
