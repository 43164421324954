import { API_PATH, PATIENTS_PATH } from '../config';

import type { MessageTypeEnum } from '@almond/api-types';

export const CREATE_PATIENT_PATH = (profileUuid: string) =>
  `${API_PATH}patients/${profileUuid as `{patient_profile_id}`}` as const;
export const CONSENTS_PATH = `${PATIENTS_PATH}consents/` as const;
export const HISTORIES_PATH = `${PATIENTS_PATH}histories/` as const;
export const LAB_ORDERS_PATH = `${PATIENTS_PATH}lab_orders/` as const;
export const PHARMACIES_PATH = `${PATIENTS_PATH}pharmacies/` as const;
export const SUBSCRIPTIONS_PATH = `${PATIENTS_PATH}subscriptions/` as const;
export const REFERRAL_PATH = `${PATIENTS_PATH}referral/` as const;
export const GET_INSURANCE_PATH = `${PATIENTS_PATH}insurance/` as const;
export const QUESTIONNAIRES_PATH = `${PATIENTS_PATH}questionnaires/` as const;
export const SUBMIT_QUESTIONNAIRE_PATH = (questionnaireUuid: string) =>
  `${PATIENTS_PATH}questionnaires/${questionnaireUuid as '{questionnaire_uuid}'}` as const;
export const DIAGNOSIS_PATH = `${PATIENTS_PATH}diagnoses/` as const;
export const TYPEFORM_PATH = `${PATIENTS_PATH}typeform/` as const;
export const SYSTEM_MESSAGES_PATH = (messageType: MessageTypeEnum) =>
  `${PATIENTS_PATH}system_messages/${messageType as '{message_type}'}/` as const;
