import type { StringifiedBoolean } from './navigationWithoutRouting';
import type { DiagnosisCodesEnum, MedicationTypesEnum } from '@almond/api-types';

export const BOOK_APPOINTMENT_PAGE_NAME = '/book-appointment' as const;
export const CONFIRMED_PAGE_NAME = '/confirmed' as const;
export const CONFIRM_EMAIL_PAGE_NAME = '/confirm-email' as const;
export const CONFIRM_TREATMENT_PAGE_NAME = '/confirm-treatment' as const;
export const LAB_ORDERS_PAGE_NAME = '/lab' as const;
export const LEARN_MORE_PAGE_NAME = '/learn-more' as const;
export const MAIN_SYMPTOMS_PAGE_NAME = '/main-symptoms' as const;
export const NEW_MEMBER_SCHEDULING_PAGE_NAME = '/new-member-scheduling' as const;
export const NEXT_STEPS_PAGE_NAME = '/next-steps' as const;
export const RECOMMENDATION_PAGE_NAME = '/recommendation' as const;
export const SCHEDULING_PAGE_NAME = '/scheduling' as const;
export const TELL_US_MORE_PAGE_NAME = '/tell-us-more' as const;
export const TREATMENT_PAGE_NAME = '/treatment' as const;
export const VISIT_REASONS_PAGE_NAME = '/visit-reasons' as const;

export type BookAppointmentParams = undefined;
export type ConfirmedParams = { can_continue?: StringifiedBoolean };
export type ConfirmEmailParams = undefined;
export type ConfirmTreatmentParams = undefined;
export type LabOrdersParams = undefined;
export type LearnMoreParams = undefined;
export type MainSymptomsParams = undefined;
export type NewMemberSchedulingParams = undefined;
export type NextStepsParams = { type: MedicationTypesEnum };
export type RecommendationParams = { infectionOutcome?: StringifiedBoolean };
export type SchedulingParams = { showAll?: StringifiedBoolean; infectionOutcome?: StringifiedBoolean };
export type TellUsMoreParams = undefined;
export type TreatmentParams = { key: DiagnosisCodesEnum };
export type VisitReasonsParams = undefined;
