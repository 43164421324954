import { accountSetupRoutes } from './accountSetup';
import { bookingRoutes } from './booking';
import { curatedFlowRoutes } from './curatedFlow';
import { historyRoutes } from './history';
import { initialRoutes } from './initial';
import { initialNewMemberRoutes } from './initialNewMember';
import { labRoutes } from './lab';
import { postBookingAdminRoutes } from './postBookingAdmin';
import { postBookingSelfRoutes } from './postBookingSelf';
import { stiRoutes } from './sti';
import { testingRoutes } from './testing';
import { vaginitisRoutes } from './vaginitis';
import { vaginitisRxRoutes } from './vaginitisRx';

const allRoutes = {
  [curatedFlowRoutes.id]: curatedFlowRoutes,
  [initialRoutes.id]: initialRoutes,
  [initialNewMemberRoutes.id]: initialNewMemberRoutes,
  [labRoutes.id]: labRoutes,
  [stiRoutes.id]: stiRoutes,
  [vaginitisRoutes.id]: vaginitisRoutes,
  [vaginitisRxRoutes.id]: vaginitisRxRoutes,
  [testingRoutes.id]: testingRoutes,
  [historyRoutes.id]: historyRoutes,
  [accountSetupRoutes.id]: accountSetupRoutes,
  [bookingRoutes.id]: bookingRoutes,
  [postBookingSelfRoutes.id]: postBookingSelfRoutes,
  [postBookingAdminRoutes.id]: postBookingAdminRoutes,
};

export default allRoutes;
