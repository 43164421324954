import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { emailsApi } from '~modules/api';
import { emailsUtilities } from '~modules/emails';
import { useArtemisRedirectUrl } from '~modules/integration';
import { patientProgressAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

import { log } from '../logger';

import type { EmailSideEffects, EmailTemplates } from '@almond/api-types';
import type { SendEmailInParams } from '~types';

const useSendEmail = () => {
  const isAdmin = useIsAdmin();
  const artemisRedirectUrl = useArtemisRedirectUrl();
  const searchParams = useLocalSearchParams();

  return useRecoilCallback(
    // eslint-disable-next-line max-statements
    callbackInterface => async (bookingUuid?: string) => {
      const patientProgressState = await callbackInterface.snapshot.getPromise(patientProgressAtom);

      // We don't need to send any emails for the lab flows.
      if (searchParams.visit_reason === 'assisted_lab') {
        return log("Lab flow is active, so we don't want to send any emails.");
      }

      if (
        isAdmin &&
        searchParams.is_new_member !== 'true' &&
        patientProgressState.historyData &&
        patientProgressState.consent &&
        patientProgressState.pharmacy &&
        patientProgressState.address &&
        patientProgressState.referral &&
        patientProgressState.subscription
      ) {
        // Returning patients that have no additional info to fill out don't need an email
        return;
      }

      const sendEmailInParams: SendEmailInParams = {
        patient_uuid: searchParams.patient_uuid,
        appointment_uuid: searchParams.appointment_uuid,
        email: searchParams.email,
        profile_uuid: searchParams.profile_uuid,
        booking_uuid: bookingUuid ?? searchParams.booking_uuid,
        artemis_redirect_url: artemisRedirectUrl,
      };

      let emailTemplate: EmailTemplates | undefined;
      let sideEffect: EmailSideEffects | undefined;
      let linkPath: string | undefined;

      if (isAdmin && searchParams.is_new_member !== 'true') {
        // Admin/Returning Patient
        linkPath = '/tell_us_more';
        emailTemplate = 'tell_us_more';
        sideEffect = 'verify_email';
      } else if (isAdmin && searchParams.is_new_member === 'true') {
        // Admin/New Patient
        linkPath = '/tell_us_more';
        emailTemplate = 'welcome_email';
        sideEffect = 'set_password';
      } else if (!patientProgressState.emailVerified) {
        // Patient/New Patient
        linkPath = '/complete_on_boarding';
        emailTemplate = searchParams.is_new_member === 'true' ? 'welcome_email' : 'complete_on_boarding';
        sideEffect = 'verify_email';
      }

      if (!emailTemplate || !sideEffect || !linkPath) return;

      await emailsApi.sendEmail(emailTemplate)(
        emailsUtilities.generateSendEmailIn(linkPath, sendEmailInParams, sideEffect)
      );
    },
    [
      artemisRedirectUrl,
      isAdmin,
      searchParams.appointment_uuid,
      searchParams.booking_uuid,
      searchParams.email,
      searchParams.is_new_member,
      searchParams.patient_uuid,
      searchParams.profile_uuid,
      searchParams.visit_reason,
    ]
  );
};

export default useSendEmail;
