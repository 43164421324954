import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useSaveQuestionnaire } from '~modules/questionnaire/hooks';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom, questionnaireAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, MEDICATIONS_PAGE_NAME, QuestionnaireState } from '~types';

export const MedicationsPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const setQuestionnaireState = useSetRecoilState(questionnaireAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const saveQuestionnaire = useSaveQuestionnaire();
  const { dispatch, routingConfigId } = useRouteNavigation<typeof MEDICATIONS_PAGE_NAME>();

  const isVaginitis = routingConfigId === 'vaginitisRx';

  const handleHistorySubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, medications: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'medications', 'medications', t('medications.skipTitle'));
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, t, dispatch]
  );
  const handleQuestionnaireSubmit = (formValues: FormValues<QuestionnaireState> | null) => {
    if (!formValues) {
      setQuestionnaireState(prevState => ({ ...prevState, medications: undefined }));
    }

    const toCall = async (): Promise<void> => {
      await saveQuestionnaire(formValues, 'medications', 'medications');
      await dispatch('submit');
    };

    doAsync(toCall);
  };

  return (
    <MainForm
      id="medications"
      title={t(isVaginitis ? 'medications.titleOther' : 'medications.title')}
      submitButtonTitle={t('continue')}
      onSubmit={isVaginitis ? handleQuestionnaireSubmit : handleHistorySubmit}
      secondaryButtonTitle={t('medications.skipTitle')}
      onSecondaryPress={() => (isVaginitis ? handleQuestionnaireSubmit(null) : handleHistorySubmit(null))}
      atom={isVaginitis ? questionnaireAtom : historyAtom}
      requiredFields={['medications']}
      isLoading={isLoading}
    >
      <ConnectedTextInput
        name="medications"
        multiline
        placeholder={t('medications.placeholder')}
        testID="Medications"
      />
    </MainForm>
  );
};
