import React, { useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useToggle } from '@almond/utils';
import { useGlobalSearchParams } from 'expo-router';

import { feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';

import { useRedirectBackToArtemis } from '../../hooks';
import { ConfirmationModal } from '../ConfirmationModal';
import { ContactUsModal } from '../ContactUsModal';
import { FeedbackModal } from '../FeedbackModal';
import { IdleModal } from '../IdleModal';
import { useRunOnUserIdle } from './useRunOnUserIdle';
import { useShouldDisplayIdleModal } from './useShouldDisplayIdleModal';

export const IdleModalWrapper = () => {
  const [isVisible, toggleIsVisible] = useToggle(false);
  const shouldDisplayIdleModal = useShouldDisplayIdleModal();
  const [activeModal, setActiveModal] = useState<'idle' | 'contactUs' | 'feedback' | 'confirmation'>('idle');
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync } = useAsync();
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const { t } = useTranslation();
  const searchParams = useGlobalSearchParams();

  useRunOnUserIdle(toggleIsVisible, shouldDisplayIdleModal && !isVisible);

  if (!shouldDisplayIdleModal || !isVisible) {
    return null;
  }

  const handlePress = (key: string, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (searchParams.is_new_member !== 'true') {
        patientResponse.patientUuid = searchParams.patient_uuid;
      }

      const response = await feedbackApi.createFeedback()({
        eventType: 'idle',
        response: patientResponse,
      });

      setFeedbackUuid(response.uuid);

      if (key === 'somethingElse') {
        setActiveModal('feedback');
      } else if (key === 'contactUs') {
        if (searchParams.is_new_member === 'true') {
          setActiveModal('contactUs');
        } else {
          toggleIsVisible();
          redirectBackToArtemis('/messages');
        }
      } else {
        setActiveModal('confirmation');
      }
    };

    doAsync(toCall);
  };

  const onRequestClose = () => {
    toggleIsVisible();
    setActiveModal('idle');
  };

  return (
    <>
      <IdleModal isVisible={activeModal === 'idle'} onRequestClose={onRequestClose} onPress={handlePress} />
      <ContactUsModal
        isVisible={activeModal === 'contactUs'}
        onRequestClose={onRequestClose}
        onBack={() => setActiveModal('idle')}
        onPress={() => setActiveModal('confirmation')}
      />
      {feedbackUuid && (
        <FeedbackModal
          isVisible={activeModal === 'feedback'}
          onRequestClose={onRequestClose}
          feedbackUuid={feedbackUuid}
          onSubmit={() => setActiveModal('confirmation')}
          eventType="idle"
          onBack={() => setActiveModal('idle')}
        />
      )}
      <ConfirmationModal
        isVisible={activeModal === 'confirmation'}
        onRequestClose={onRequestClose}
        onPress={onRequestClose}
        submitTitle={t('done')}
      />
    </>
  );
};
