import React from 'react';

import { useTranslation } from '@almond/localization';

import { CheckboxGroup, MainForm, useForm } from '~modules/forms';
import { useLoadPatient } from '~modules/patient/hooks';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';

import { MEDICAL_CONDITIONS_ITEMS } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { MEDICAL_CONDITIONS_PAGE_NAME, QuestionnaireState } from '~types';

export const MedicalConditionsPage: React.FC = () => {
  const { t } = useTranslation();
  const formReturn = useForm({ atom: questionnaireAtom });
  const saveQuestionnaire = useSaveQuestionnaire();
  const loadPatient = useLoadPatient();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof MEDICAL_CONDITIONS_PAGE_NAME>();

  const handleSubmit = (values: FormValues<QuestionnaireState>): void => {
    const toCall = async (): Promise<void> => {
      if (!values.medicalConditions) return;

      await Promise.all([
        // Cache time since last appointment, if patient is logged in,
        // for use in dispatch callback
        loadPatient(),
        // Save questionnaire
        saveQuestionnaire(values, 'medical_conditions', 'medicalConditions'),
      ]);
      const event =
        values.medicalConditions.length === 1 &&
        values.medicalConditions.find(medicalCondition => medicalCondition.id === 'none_of_these')
          ? 'noneOfThese'
          : 'submit';

      await dispatch(event);
    };

    doAsync(toCall);
  };

  return (
    <MainForm
      id="medicalConditions"
      title={t('medicalConditions.title')}
      submitButtonTitle={t('continue')}
      atom={questionnaireAtom}
      requiredFields={['medicalConditions']}
      formReturn={formReturn}
      size="M"
      onSubmit={handleSubmit}
      isLoading={isLoading}
    >
      <CheckboxGroup name="medicalConditions" items={MEDICAL_CONDITIONS_ITEMS} noneId="none_of_these" />
    </MainForm>
  );
};
