import React from 'react';
import { View } from 'react-native';

import { BaseButton, Link, List, MaterialIcon, sizes, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { TreatmentOption } from '~types';

export type ItemProps = {
  item: TreatmentOption;
  isSelected: boolean;
  onPress: (item: TreatmentOption) => void;
};

const Item: React.FC<ItemProps> = props => {
  const { item, isSelected, onPress } = props;
  const [styles] = useTheme(themedStyles);
  const textStyle = isSelected ? styles.selectedText : styles.text;

  return (
    <BaseButton
      onPress={() => onPress(item)}
      style={[styles.item, isSelected && styles.itemSelected]}
      testID={item.id}
      accessibilityRole="radio"
      aria-selected={isSelected}
    >
      {isSelected && <MaterialIcon source="check" color="background" style={styles.icon} size={sizes.SPACING_L} />}
      <View style={styles.contentContainer}>
        <Text fontStyle="bold" size="xl" style={[styles.title, textStyle]}>
          {item.title}
        </Text>
        {item.description && (
          <Text size="s" style={[styles.description, textStyle]}>
            {item.description}
          </Text>
        )}
        {item.items && (
          <List.UnorderedList style={styles.items}>
            {item.items.map((thisItem, index) => (
              <List.ListItem key={index} style={textStyle}>
                {
                  <Text size="s" style={textStyle}>
                    {thisItem}
                  </Text>
                }
              </List.ListItem>
            ))}
          </List.UnorderedList>
        )}
        {item.link && item.href && (
          <Link style={[styles.link, isSelected && styles.linkSelected]} url={item.href}>
            {item.link}
          </Link>
        )}
      </View>
    </BaseButton>
  );
};

export default Item;
