import React, { Fragment } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { SENTRY_UNMASK, SkeletonLoader, Text, useTheme } from '@almond/ui';
import groupBy from 'object.groupby';

import { getVisitReasonIcon } from '~assets';

import { BlockItemLink } from '../../components';

import themedStyles from './styles';

import type { VisitReasonOut } from '@almond/api-types';

type VisitReasonsProps = {
  isLoading: boolean;
  visitReasons: VisitReasonOut[] | undefined;
  onSubmit: (item: string) => Promise<void>;
};

export const VisitReasons = (props: VisitReasonsProps) => {
  const { isLoading, visitReasons, onSubmit } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();

  if (isLoading || !visitReasons) {
    return (
      <View style={styles.loaderContainer}>
        <Text fontStyle="bold" size="m" style={[styles.sectionTitle, SENTRY_UNMASK]}>
          {t('visitReasons.generalSection')}
        </Text>
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
      </View>
    );
  }

  // Unique categories, in order of first appearance
  const categoryOrder = [...new Set(visitReasons.map(v => v.category))];
  const groupedVisitReasons = groupBy(visitReasons, v => v.category);

  return (
    <>
      {categoryOrder.map(category => {
        // Category used to be a key ("general", "maternity"). We're shifting to "human readable"
        // categories. Once "general" and "maternity" switch to human readable in production, we can
        // remove the handling of those values (and their translation keys).

        const title =
          category === 'maternity' || category === 'general' ? t(`visitReasons.${category}Section`) : category;

        return (
          <Fragment key={category}>
            <Text fontStyle="bold" size="m" style={[styles.sectionTitle, SENTRY_UNMASK]}>
              {title}
            </Text>
            {groupedVisitReasons[category].map(item => (
              <BlockItemLink
                key={item.uuid}
                testID={item.code}
                title={item.title}
                subtitle={item.description}
                icon={getVisitReasonIcon(item.code)}
                onPress={() => onSubmit(item.code)}
              />
            ))}
          </Fragment>
        );
      })}
    </>
  );
};
