import { useCallback } from 'react';

import { useRouter } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { patientAtom, patientProgressAtom } from '~modules/state';

import type { PatientDetailOut, SubscriptionOut } from '@almond/api-types';

const useProcessPatientResponse = (): ((response: PatientDetailOut, subscription: SubscriptionOut | null) => void) => {
  const setPatientState = useSetRecoilState(patientAtom);
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const { setParams } = useRouter();

  return useCallback(
    (response: PatientDetailOut, subscription: SubscriptionOut | null) => {
      setParams({ patient_uuid: response.uuid });
      setPatientState(prevState => ({
        ...prevState,
        lastSeenTimestamp: response.lastSeenTimestamp,
      }));
      setPatientProgressState(prevState => ({
        ...prevState,
        emailVerified: response.emailVerified,
        consent: response.consent,
        lab: response.lab,
        pharmacy: response.pharmacy,
        referral: response.referral,
        historyData: response.historyData,
        address: response.profile?.address,
        subscription: subscription?.status ? ['active', 'trialing'].includes(subscription.status) : false,
      }));
    },
    [setParams, setPatientProgressState, setPatientState]
  );
};

export default useProcessPatientResponse;
