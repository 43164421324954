import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { CONTACT_US_PHONE_NUMBER, Link, useTheme } from '@almond/ui';
import { useTimeout } from '@almond/utils';

import { BaseModal } from '../BaseModal';
import { ModalButton } from '../ModalButton';

import { themedStyles } from './styles';

import type { BaseModalProps } from '../BaseModal';

export type ContractUsModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  onPress: () => void;
};

const url = `+1${CONTACT_US_PHONE_NUMBER.replace(/[ ()-]/g, '')}`;

export const ContactUsModal = (props: ContractUsModalProps) => {
  const { onPress, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const [withTimeout] = useTimeout();

  const handlePress = () => {
    // Setting a short timeout in order not to block modals.
    withTimeout(onPress, 100);
  };

  return (
    <BaseModal title={t('contactUsModal.title')} subtitle={t('contactUsModal.subtitle')} {...restProps}>
      <View style={styles.contentContainer}>
        <Link urlType="phoneNumber" url={url}>
          <ModalButton onPress={handlePress}>{t('contactUsModal.callUs')}</ModalButton>
        </Link>
        <Link urlType="sms" url={url}>
          <ModalButton onPress={handlePress}>{t('contactUsModal.textUs')}</ModalButton>
        </Link>
      </View>
    </BaseModal>
  );
};
