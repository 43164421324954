import { appointmentUtilities } from '~modules/appointment/services';

import type { QuestionnaireInWithAppointment, TreatmentPreferencesEnum } from '@almond/api-types';
import type { QuestionnaireState } from '~types';

export const parseToPatientQuestionnaireIn = (
  questionnaireState: QuestionnaireState,
  currentState: QuestionnaireInWithAppointment['currentState'],
  appointmentUuid?: string,
  visitReason?: string
): QuestionnaireInWithAppointment => {
  return {
    ...questionnaireState,
    appointmentUuid,
    mainSymptoms: questionnaireState.mainSymptoms,
    symptoms: questionnaireState.symptoms || undefined,
    pregnant: questionnaireState.pregnant?.id || undefined,
    visitQuestions: questionnaireState.visitQuestions || undefined,
    vaginalInfectionSymptoms: questionnaireState.vaginalInfectionSymptoms?.map(symptom => symptom.id) || undefined,
    medicalConditions: questionnaireState.medicalConditions?.map(symptom => symptom.id) || undefined,
    antibiotics: questionnaireState.antibiotics?.id || undefined,
    vaginalInfectionFrequency: questionnaireState.vaginalInfectionFrequency?.id || undefined,
    outcome: questionnaireState.outcome || undefined,
    currentState,
    source: appointmentUtilities.curatedFlow(visitReason),
    treatmentPreference: questionnaireState.treatmentPreference as Exclude<
      TreatmentPreferencesEnum,
      'self_serve_testing'
    >[],
  };
};
