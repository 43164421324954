import type { SelectInputValue } from '@almond/ui';

export const omitNullishFields = <T extends Record<string, any>>(object: T): T => {
  const dataTypesToOmit = ['', null, undefined, NaN];

  return Object.keys(object).reduce((acc, cur: keyof T) => {
    if (!dataTypesToOmit.includes(object[cur])) return { ...acc, [cur]: object[cur] };

    return acc;
  }, {} as T);
};

export const isObjectEmpty = <T extends Record<string, any>>(object: T): boolean => {
  if (!object || typeof object !== 'object') return true;

  return !Object.keys(object).length;
};

export const removeEmptyObjects = <T extends Record<string, any>>(array: T[]): T[] => {
  return array.filter(item => !isObjectEmpty(item));
};

export const transformSelectInputValuesToApiEnumObject = <Enum extends string>(
  array: SelectInputValue<Enum>[]
): Record<string, Enum> => {
  const toReturn: Record<string, Enum> = {};

  array.forEach(item => {
    if (item.key && item.value) {
      toReturn[item.key] = item.value.value;
    }
  });

  return toReturn;
};

export const transformSelectInputValuesToApiEnumList = <Enum extends string>(
  array: SelectInputValue<Enum>[]
): Record<string, Enum>[] => {
  return array
    .filter((i): i is Required<typeof i> => Boolean(i.key && i.value))
    .map(item => ({ [item.key]: item.value.value }));
};

// This allows us to serialize array query / path parameters in the following form:
// param1=x&param1=y&param1=z. This works better natively with fastapi
export const serializeParameters = <T extends Record<string, any>>(unserializedParams: T): string => {
  const urlParams = new URLSearchParams();

  Object.entries(unserializedParams).forEach(([key, values]) => {
    if (Array.isArray(values)) {
      values.forEach((value: string) => urlParams.append(key, value));
    } else if (typeof values === 'object' && values !== null) {
      urlParams.append(key, JSON.stringify(values));
    } else {
      urlParams.append(key, String(values));
    }
  });

  return urlParams.toString();
};

export const ensureString = (obj: Record<string, any> | null, key: string): string => {
  if (!obj) {
    throw new Error(`Expected ${key} value to be a string, but the parent object was ${obj}`);
  }

  if (typeof obj[key] === 'string') {
    return obj[key];
  }

  throw new Error(`Expected ${key} value to be a string, got ${obj[key]} instead`);
};
