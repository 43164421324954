import { atom } from 'recoil';

import {
  APPOINTMENT_AVAILABILITY_METADATA_STATE_KEY,
  APPOINTMENT_PARAMS_STATE_KEY,
  DEFAULT_APPOINTMENT_AVAILABILITY_METADATA_STATE,
  DEFAULT_APPOINTMENT_PARAMS_STATE,
  DEFAULT_PRESCRIPTION_STATE,
  PRESCRIPTION_STATE_KEY,
} from './config';

export const appointmentAvailabilityMetadataAtom = atom({
  key: APPOINTMENT_AVAILABILITY_METADATA_STATE_KEY,
  default: DEFAULT_APPOINTMENT_AVAILABILITY_METADATA_STATE,
});

export const appointmentParamsAtom = atom({
  key: APPOINTMENT_PARAMS_STATE_KEY,
  default: DEFAULT_APPOINTMENT_PARAMS_STATE,
});

export const prescriptionAtom = atom({
  key: PRESCRIPTION_STATE_KEY,
  default: DEFAULT_PRESCRIPTION_STATE,
});
