import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Link, SkeletonLoader, Text, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { getVisitReasonIcon } from '~assets';
import { useArtemisRedirectUrl } from '~modules/integration';

import { BlockItemLink } from '../../components';

import themedStyles from './styles';

import type { VisitReasonOut } from '@almond/api-types';

type NewMemberVisitReasonsProps = {
  isLoading: boolean;
  visitReasons: VisitReasonOut[] | undefined;
  onSubmit: (item: string) => Promise<void>;
};

export const NewMemberVisitReasons = (props: NewMemberVisitReasonsProps) => {
  const { isLoading, visitReasons, onSubmit } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const searchParams = useLocalSearchParams();
  const artemisRedirectUrl = useArtemisRedirectUrl();

  if (isLoading || !visitReasons) {
    return (
      <View style={styles.loaderContainer}>
        <Text style={styles.instructions} fontStyle="medium">
          {t('visitReasons.pickOne')}
        </Text>
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
      </View>
    );
  }

  return (
    <>
      <Text style={styles.instructions} fontStyle="medium">
        {t('visitReasons.pickOne')}
      </Text>
      {visitReasons?.map(item => (
        <BlockItemLink
          key={item.uuid}
          testID={item.code}
          title={item.title}
          icon={getVisitReasonIcon(item.code)}
          onPress={() => onSubmit(item.code)}
        />
      ))}
      {searchParams.is_new_member === 'true' && (
        <Link url={artemisRedirectUrl} size="m" style={styles.returningMember}>
          {t('visitReasons.returningMember')}
        </Link>
      )}
    </>
  );
};
