import type { TreatmentRecommendationId } from '../shared';

export const ANTIBIOTICS_PAGE_NAME = '/antibiotics' as const;
export const FREQUENCY_PAGE_NAME = '/frequency' as const;
export const MEDICAL_CONDITIONS_PAGE_NAME = '/medical-conditions' as const;
export const PREGNANT_PAGE_NAME = '/pregnant' as const;
export const TESTING_PAGE_NAME = '/testing' as const;
export const TREATMENT_RECOMMENDATION_PAGE_NAME = '/treatment-recommendation' as const;
export const VAGINAL_INFECTION_SYMPTOMS_PAGE_NAME = '/vaginal-infection-symptoms' as const;

export type AntibioticsParams = undefined;
export type FrequencyParams = undefined;
export type MedicalConditionsParams = undefined;
export type PregnantParams = undefined;
export type TestingParams = undefined;
export type TreatmentRecommendationParams = { id: TreatmentRecommendationId };
export type VaginalInfectionSymptomsParams = undefined;
