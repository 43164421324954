import type { VisitOutcomeSchema } from '../../visitOutcomes';
import type { PhysicianTimeSlots } from '@almond/api-types';

export const isAppointmentPrimary = (
  visitOutcome: VisitOutcomeSchema | null,
  physicianTimeSlots: PhysicianTimeSlots
) => {
  if (!visitOutcome) {
    return false;
  }

  const { isTelehealth, providerTypes } = visitOutcome;
  const { appointmentTypeDetails, physician } = physicianTimeSlots;

  if (typeof isTelehealth === 'boolean') {
    if (
      (appointmentTypeDetails.location === 'Office' && isTelehealth) ||
      (appointmentTypeDetails.location === 'Telehealth' && !isTelehealth)
    ) {
      return false;
    }
  }

  if (Array.isArray(providerTypes) && providerTypes.length) {
    if (!providerTypes.includes(physician.providerType)) {
      return false;
    }
  }

  return true;
};
