import React, { useCallback, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useFocusEffect, useLocalSearchParams, useRouter } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { useVisitOutcome } from '~modules/appointment/hooks';
import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { questionnaireAtom } from '~modules/state';
import { InfoMessage } from '~modules/ui';

import { TREATMENT_RECOMMENDATION_CONFIG } from '../../config';
import { useSaveQuestionnaire } from '../../hooks';

import type { QuestionnaireState, TREATMENT_RECOMMENDATION_PAGE_NAME, TreatmentRecommendationParams } from '~types';

export const TreatmentRecommendationPage: React.FC = () => {
  const { t } = useTranslation();
  const searchParams = useLocalSearchParams<TreatmentRecommendationParams>();
  const { setParams } = useRouter();
  const config = TREATMENT_RECOMMENDATION_CONFIG[searchParams.id];
  const { dispatch } = useRouteNavigation<typeof TREATMENT_RECOMMENDATION_PAGE_NAME>();
  const [visitReasonBackup] = useState(searchParams.visit_reason);
  const setQuestionnaireState = useSetRecoilState(questionnaireAtom);
  const saveQuestionnaire = useSaveQuestionnaire();
  const { doAsync, isLoading } = useAsync();
  const { visitOutcome } = useVisitOutcome();
  const isRx = ['yeastRx', 'bacterialVaginosisRx'].includes(searchParams.id);

  const handleSubmit = () => {
    const toCall = async () => {
      const questionnaireData = {} as QuestionnaireState;

      if (config.outcome) {
        questionnaireData.outcome = config.outcome;
      }

      if (config.id === 'bacterialVaginosisRx') {
        questionnaireData.diagnosis = 'acute_vaginitis';
      } else if (config.id === 'yeastRx') {
        questionnaireData.diagnosis = 'yeast_infection';
      }

      setQuestionnaireState(prevState => ({ ...prevState, ...questionnaireData }));

      await saveQuestionnaire(questionnaireData, 'diagnosis', ['diagnosis', 'outcome']);
      await dispatch('submit', searchParams.id);
    };

    doAsync(toCall);
  };

  const resetAppointmentParams = (visitReasonIdToReset?: string) => {
    if (visitReasonIdToReset) {
      setParams({ visit_reason: visitReasonIdToReset });
    }
  };

  const handleSecondary = async () => {
    let visitReasonIdToReset: string | undefined;

    if (!isRx) {
      visitReasonIdToReset = 'other';
    }

    await dispatch(
      'secondary',
      searchParams.id,
      visitReasonIdToReset ? { visit_reason: visitReasonIdToReset } : undefined
    );
  };

  useFocusEffect(
    useCallback(() => {
      // Resetting the visit reason on focus if it was changed using the secondary action.
      setParams({ visit_reason: visitReasonBackup });
    }, [setParams, visitReasonBackup])
  );

  return (
    <MainForm
      id="treatmentRecommendation"
      title={t('treatmentRecommendation.title')}
      submitButtonTitle={t(
        isRx ? `treatmentRecommendation.message.${config.id}.submitTitle` : 'seeAvailableVisitTimes'
      )}
      secondaryButtonTitle={isRx ? config.secondaryTitle : t('bookGeneralVisit')}
      size="M"
      onSubmit={handleSubmit}
      onSecondaryPress={handleSecondary}
      isLoading={isLoading}
      onBack={resetAppointmentParams}
    >
      <InfoMessage
        title={isRx ? t(`treatmentRecommendation.message.${config.id}.title`) : visitOutcome?.curatedCopy?.title}
        description={
          isRx ? t(`treatmentRecommendation.message.${config.id}.description`) : visitOutcome?.curatedCopy?.description
        }
        linkTitle={config.linkTitle}
        link={config.link}
      />
    </MainForm>
  );
};
