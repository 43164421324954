import React from 'react';

import { useTranslation } from '@almond/localization';
import { CONTACT_US_PHONE_NUMBER, Link } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { useRedirectBackToArtemis } from '~modules/integration';

export const ContactUs = () => {
  const { t } = useTranslation();
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const searchParams = useLocalSearchParams();

  if (searchParams.is_new_member === 'true') {
    return (
      <Link url={CONTACT_US_PHONE_NUMBER} urlType="phoneNumber" onPress={undefined}>
        {t('tellUsMore.newContactUs', { phoneNumber: CONTACT_US_PHONE_NUMBER })}
      </Link>
    );
  }

  return <Link onPress={() => redirectBackToArtemis()}>{t('tellUsMore.returningContactUs')}</Link>;
};
