import type { ProductOut } from '@almond/api-types';
import type { PromotionCode } from '~types';

export const calculateDiscount = (product: ProductOut, promotionCode: PromotionCode | undefined) => {
  let amountOff = 0;

  if (!promotionCode || promotionCode.state !== 'success') {
    return amountOff;
  }

  if (promotionCode.amountOff) {
    amountOff = promotionCode.amountOff;
  }

  if (promotionCode.percentOff) {
    amountOff = product.price.amount * (Number(promotionCode.percentOff) / 100);
  }

  return amountOff;
};

export const calculateDiscountedTotalPrice = (product: ProductOut, promotionCodes: (PromotionCode | undefined)[]) => {
  let currentPrice = product.price.amount;

  for (let i = 0; i < promotionCodes.length; i += 1) {
    currentPrice -= calculateDiscount(product, promotionCodes[i]);
  }

  // Don't allow it to go negative
  return Math.max(currentPrice, 0);
};

export const isPromotionCodeRepeating = (promotionCode?: PromotionCode) => {
  if (!promotionCode || promotionCode.state !== 'success') {
    return false;
  }

  const isForever = promotionCode?.duration === 'forever';
  const isRepeatingAfterOneYear =
    promotionCode?.duration === 'repeating' && promotionCode?.durationInMonths && promotionCode.durationInMonths > 12;

  return isForever || !!isRepeatingAfterOneYear;
};

export const calculateRenewalPrice = (product: ProductOut, promotionCodes: (PromotionCode | undefined)[]) => {
  const repeatingCodes = promotionCodes.filter(promotionCode => {
    return isPromotionCodeRepeating(promotionCode);
  });

  return calculateDiscountedTotalPrice(product, repeatingCodes);
};
