import { useMemo } from 'react';

import dayjs from 'dayjs';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { appointmentParamsAtom, patientAtom, questionnaireAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

import { visitOutcomesSchema } from '../visitOutcomes';

import type { VisitOutcome } from '../visitOutcomes';

// TODO: Cover with unit tests.
export const useVisitOutcome = (): {
  visitOutcome: VisitOutcome | null;
  notSeenInLastYear: boolean;
} => {
  const patientState = useRecoilValue(patientAtom);
  const questionnaireState = useRecoilValue(questionnaireAtom);
  const searchParams = useLocalSearchParams();
  const visitReason = searchParams.visit_reason;
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const isNewMember = appointmentParamsState.isNewMemberRemote;
  const isAdmin = useIsAdmin();
  const hasSkinSymptoms = questionnaireState.mainSymptoms?.some(symptom => symptom.id === 'ulb');
  const infectionOutcome = searchParams.infectionOutcome === 'true';
  const showAll = searchParams.showAll === 'true';
  const notSeenInLastYear =
    !patientState.lastSeenTimestamp || dayjs(patientState.lastSeenTimestamp).isBefore(dayjs().subtract(1, 'year'));

  return useMemo(() => {
    if (!visitReason) {
      return { visitOutcome: null, notSeenInLastYear };
    }

    const args = {
      isNewMember,
      hasSkinSymptoms,
      notSeenInLastYear,
      infectionOutcome,
      showAll,
      questionnaire: {
        pregnant: questionnaireState.pregnant?.id === 'yes',
        medicalConditions: !!questionnaireState.medicalConditions?.filter(
          medicalCondition => medicalCondition.id !== 'none_of_these'
        ).length,
        antibiotics: questionnaireState.antibiotics?.id === 'yes',
        vaginalInfectionFrequency: questionnaireState.vaginalInfectionFrequency?.id === 'yes',
      },
    };

    const visitOutcomeFactory = visitOutcomesSchema[visitReason];

    if (!visitOutcomeFactory) {
      // User manually entered invalid visit reason into URL
      return { visitOutcome: null, notSeenInLastYear };
    }

    if (!isAdmin && visitOutcomeFactory(args)?.adminOnly) {
      return { visitOutcome: null, notSeenInLastYear };
    }

    return { visitOutcome: visitOutcomeFactory(args), notSeenInLastYear };
  }, [
    hasSkinSymptoms,
    infectionOutcome,
    isAdmin,
    isNewMember,
    notSeenInLastYear,
    questionnaireState.antibiotics?.id,
    questionnaireState.medicalConditions,
    questionnaireState.pregnant?.id,
    questionnaireState.vaginalInfectionFrequency?.id,
    visitReason,
    showAll,
  ]);
};
