import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { patientsApi } from '~modules/api';
import { questionnaireAtom } from '~modules/state';

import { logAndCapture } from '../logger';
import { questionnaireParsers } from '../services';

import type { QuestionnaireInWithAppointment } from '@almond/api-types';

// useCompleteQuestionnaire should be used to complete an existing questionnaire for a patient
// When this is used, the intake form gets published to the patient chart with all the answers
// Returning patients: this should be called from the last question in the questionnaire (e.g visit questions)
// New patients: this should be called when the patient confirms their booking
const useCompleteQuestionnaire = () => {
  const searchParams = useLocalSearchParams();

  return useRecoilCallback(
    callbackInterface => async (appointmentUuid?: string) => {
      const questionnaireState = await callbackInterface.snapshot.getPromise(questionnaireAtom);

      if (!questionnaireState.uuid) {
        logAndCapture("`questionnaireUuid` is not specified, so can't complete the questionnaire.");

        return;
      }

      const data: QuestionnaireInWithAppointment = questionnaireParsers.parseToPatientQuestionnaireIn(
        questionnaireState,
        'completed',
        appointmentUuid,
        searchParams.visit_reason
      );

      await patientsApi.completeQuestionnaire(questionnaireState.uuid)(data);
    },
    [searchParams.visit_reason]
  );
};

export default useCompleteQuestionnaire;
