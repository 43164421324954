import type { RenderAPI } from '@testing-library/react-native';
import type React from 'react';

export type RenderNavigationComponent = <T>(
  Component: (() => JSX.Element) | React.FC<any>,
  isFocused?: boolean,
  props?: T,
  Wrapper?: (() => JSX.Element) | React.FC<any>
) => RenderAPI;

export type RenderStoreComponent = <T>(
  Component: (() => JSX.Element) | React.FC<any>,
  props?: T,
  Wrapper?: (() => JSX.Element) | React.FC<any>
) => RenderAPI;
