import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Markdown, MaterialIcon, sizes, Text, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { Container } from '~modules/layout';
import { MessageContainer } from '~modules/ui';

import themedStyles from './styles';

import type { NextStepsParams } from '~types';

export const NextStepsPage: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const searchParams = useLocalSearchParams<NextStepsParams>();
  const { t } = useTranslation();

  return (
    <Container id={`nextSteps-${searchParams.type}`} title={t('nextSteps.title')} showImage="plant2">
      <MessageContainer style={styles.list}>
        {t(`nextSteps.${searchParams.type}`, { returnObjects: true }).map((item, index) => (
          <View key={index} style={styles.listItem}>
            <MaterialIcon source="check" size={sizes.SPACING_L} color={index === 0 ? 'primary' : 'secondaryTextDark'} />
            <Markdown size="m">{item}</Markdown>
          </View>
        ))}
        {searchParams.type === 'prescription' && (
          <Text fontStyle="bookItalic" size="m" style={styles.disclaimer}>
            {t('nextSteps.disclaimer')}
          </Text>
        )}
      </MessageContainer>
    </Container>
  );
};
