import React from 'react';

import { LabOrdersPage } from '~modules/appointment';
import { Container } from '~modules/layout';
import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';

export default withRouteBehavior(LabOrdersPage, {
  allowDirectRouting: true,
  routingConfig: configurations.lab,
  forceSearchParams: {
    is_new_member: 'false',
    visit_reason: 'assisted_lab',
  },
  restoreAuth: true,
  loadingEle: <Container id="labOrders" isLoading />,
});
