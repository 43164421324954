import { useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { useRecoilValue } from 'recoil';

import { creditCardAtom } from '~modules/state';

import { useCheckPromotionCode } from './useCheckPromotionCode';

import type { NotificationStatusBarProps } from '~modules/ui';

export const usePromotionCodeNotificationStatus = (): NotificationStatusBarProps | undefined => {
  const { t } = useTranslation();
  const creditCardState = useRecoilValue(creditCardAtom);

  useCheckPromotionCode('promotionCode', 'stripe_discount');

  return useMemo(() => {
    if (!creditCardState.promotionCode || creditCardState.promotionCode.state === 'init') {
      return;
    }

    const isSuccess = creditCardState.promotionCode?.state === 'success';

    if (!isSuccess) {
      return {
        type: 'error',
        content: t('promotionCodes.promotion.error', { code: creditCardState.promotionCode.code.toUpperCase() }),
        isVisible: true,
      };
    }

    return {
      type: 'accent',
      content: t('promotionCodes.promotion.success', { code: creditCardState.promotionCode.code.toUpperCase() }),
      isVisible: true,
    };
  }, [creditCardState.promotionCode, t]);
};
