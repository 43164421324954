import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { patientsApi } from '~modules/api';
import { questionnaireAtom } from '~modules/state';

import { log } from '../logger';
import { questionnaireParsers } from '../services';

import type { QuestionnaireInWithAppointment } from '@almond/api-types';
import type { FormValues } from '@almond/ui';
import type { QuestionnaireState } from '~types';
// useSaveQuestionnaire should be used to create or update an existing questionnaire for a patient
// Returning patients: this should be called from any question
// after the 1st question in the questionnaire (e.g pregnant)
// New patients: this will only work for new patients after they've
// logged in
const useSaveQuestionnaire = () => {
  const searchParams = useLocalSearchParams();

  return useRecoilCallback(
    callbackInterface =>
      async (
        values: FormValues<QuestionnaireState> | null,
        currentState: QuestionnaireInWithAppointment['currentState'],
        key: keyof QuestionnaireInWithAppointment | Array<keyof QuestionnaireInWithAppointment>
      ) => {
        const questionnaireState = await callbackInterface.snapshot.getPromise(questionnaireAtom);

        const valuesToUpdate = { ...questionnaireState, ...(values || {}) };

        if (!searchParams.patient_uuid) {
          log("`patient_uuid` is not specified, so can't save the questionnaire.");

          return;
        }

        const keys = Array.isArray(key) ? key : [key];

        const data: QuestionnaireInWithAppointment = questionnaireParsers.parseToPatientQuestionnaireIn(
          valuesToUpdate,
          currentState,
          undefined,
          searchParams.visit_reason
        );

        let response;

        if (questionnaireState.uuid) {
          const toSend = ([...keys, 'currentState'] as const).reduce(
            (acc: QuestionnaireInWithAppointment, thisKey) => ({
              ...acc,
              [thisKey]: data?.[thisKey],
            }),
            {} as QuestionnaireInWithAppointment
          );

          response = await patientsApi.saveQuestionnaire(questionnaireState.uuid)(toSend);
        } else {
          response = await patientsApi.createQuestionnaire(data);
        }

        const { uuid } = response;

        callbackInterface.set(questionnaireAtom, prevValue => ({ ...prevValue, uuid }));
      },
    [searchParams.patient_uuid, searchParams.visit_reason]
  );
};

export default useSaveQuestionnaire;
