import type { TreatmentRecommendationId } from './shared';
import type { OutcomesEnum } from '@almond/api-types';

export type TreatmentRecommendationConfig = {
  id: TreatmentRecommendationId;
  outcome?: OutcomesEnum;
  linkTitle?: string;
  link?: string;
  secondaryTitle: string;
};
