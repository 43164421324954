import { dateAndTimeParsers } from '@almond/date-and-time';
import { useRecoilCallback } from 'recoil';

import { appointmentsApi, patientsApi } from '~modules/api';
import { useCompleteQuestionnaire } from '~modules/questionnaire/hooks';
import { prescriptionAtom, questionnaireAtom } from '~modules/state';

import { log } from '../logger';

const useCreateVaginitisRx = () => {
  const completeQuestionnaire = useCompleteQuestionnaire();

  return useRecoilCallback(
    callbackInterface => async () => {
      const questionnaireState = await callbackInterface.snapshot.getPromise(questionnaireAtom);
      const prescriptionState = await callbackInterface.snapshot.getPromise(prescriptionAtom);

      if (!questionnaireState.uuid) {
        return log('`questionnaireUuid` is not specified, so skipping creating vaginitis rx.');
      }

      await Promise.all([
        prescriptionState.treatment?.type === 'prescription' &&
          appointmentsApi.createPrescriptionRequest({
            questionnaireUuid: questionnaireState.uuid,
            startDate: dateAndTimeParsers.toRemoteDate(new Date()),
            ...prescriptionState.treatment.details,
          }),
        completeQuestionnaire(),
        questionnaireState.diagnosis && patientsApi.createDiagnosis({ code: questionnaireState.diagnosis }),
      ]);
    },
    [completeQuestionnaire]
  );
};

export default useCreateVaginitisRx;
