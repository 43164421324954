import React from 'react';

import { BaseButton, MaterialIcon, sizes, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useVisitOutcome } from '../../hooks';

import { schedulingThemedStyles } from './styles';

type PrimaryRecommendationBarProps = {
  onPress?: () => void;
};

export const PrimaryRecommendationBar = (props: PrimaryRecommendationBarProps) => {
  const { visitOutcome } = useVisitOutcome();
  const [styles] = useTheme(schedulingThemedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  if (!visitOutcome?.copy) return null;

  return (
    <BaseButton
      style={[styles.recommendationBar, isMobile && styles.recommendationBarMobile]}
      role="button"
      onPress={props.onPress}
      testID="Scheduling_RecommendationBar"
    >
      <Text style={styles.recommendation}>{visitOutcome.copy}</Text>
      {isDesktop && (
        <MaterialIcon
          style={styles.infoIcon}
          source="info"
          color="primary"
          aria-label="Click for detailed information"
          size={sizes.SPACING_L}
        />
      )}
    </BaseButton>
  );
};
