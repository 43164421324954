import { patientProgressAtom, prescriptionAtom, questionnaireAtom } from '~modules/state';
import {
  CONFIRM_TREATMENT_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  DRUG_ALLERGIES_PAGE_NAME,
  MEDICATIONS_PAGE_NAME,
  NEXT_STEPS_PAGE_NAME,
  PHARMACY_PAGE_NAME,
  PRICING_EXPLANATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TESTING_PAGE_NAME,
  TREATMENT_PAGE_NAME,
  TREATMENT_RECOMMENDATION_PAGE_NAME,
} from '~types';

import { bookingRoutes } from './booking';
import { testingRoutes } from './testing';

import type { MedicationTypesEnum, VaginalInfectionSymptomsEnum } from '@almond/api-types';
import type { RoutingConfig, StackParamList, TreatmentRecommendationId } from '~types';

export const vaginitisRxRoutes = {
  id: 'vaginitisRx' as const,
  initialAsync: async getState => {
    const { vaginalInfectionSymptoms } = await getState(questionnaireAtom);

    // Check if 2 sets are equal
    const eqSet = (xs: Set<any>, ys: Set<any>) => xs.size === ys.size && [...xs].every(x => ys.has(x));

    const symptoms = new Set(vaginalInfectionSymptoms?.map(s => s.id));
    const yeast: Set<VaginalInfectionSymptomsEnum>[] = [
      new Set(['vaginal_itching']),
      new Set(['vaginal_itching', 'cottage_cheese_discharge']),
      new Set(['cottage_cheese_discharge']),
    ];
    const bv: Set<VaginalInfectionSymptomsEnum>[] = [
      new Set(['fishy_odor']),
      new Set(['fishy_odor', 'gray_green_discharge']),
      new Set(['fishy_odor', 'vaginal_itching']),
      new Set(['fishy_odor', 'gray_green_discharge', 'vaginal_itching']),
    ];
    let recommendation: Extract<TreatmentRecommendationId, 'bacterialVaginosisRx' | 'yeastRx' | 'testing'>;

    if (symptoms.has('something_else')) {
      recommendation = 'testing';
    } else if (yeast.some(s => eqSet(s, symptoms))) {
      recommendation = 'yeastRx';
    } else if (bv.some(s => eqSet(s, symptoms))) {
      recommendation = 'bacterialVaginosisRx';
    } else {
      recommendation = 'testing';
    }

    return { name: TREATMENT_RECOMMENDATION_PAGE_NAME, params: { id: recommendation } };
  },
  routes: {
    [TREATMENT_RECOMMENDATION_PAGE_NAME]: {
      // For Rx, or Testing
      on: {
        submit: (_1, _2, id: TreatmentRecommendationId) => {
          if (id === 'testing') {
            return testingRoutes;
          }

          return {
            name: TREATMENT_PAGE_NAME,
            params: { key: id === 'bacterialVaginosisRx' ? 'acute_vaginitis' : 'yeast_infection' },
          };
        },
        secondary: (_1, _2, id: TreatmentRecommendationId) => {
          if (id === 'testing') {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: TESTING_PAGE_NAME };
        },
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => bookingRoutes,
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
      },
    },
    [TESTING_PAGE_NAME]: {
      on: {
        submit: () => testingRoutes,
      },
    },
    [TREATMENT_PAGE_NAME]: {
      on: {
        submit: async (getState, searchParams, type: MedicationTypesEnum) => {
          if (type === 'prescription') {
            return { name: MEDICATIONS_PAGE_NAME };
          }

          const { subscription } = await getState(patientProgressAtom);

          if (!subscription && searchParams.is_new_member !== 'true') {
            return { name: PRICING_EXPLANATION_PAGE_NAME };
          }

          return { name: CONFIRM_TREATMENT_PAGE_NAME };
        },
      },
    },
    [MEDICATIONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DRUG_ALLERGIES_PAGE_NAME }),
      },
    },
    [DRUG_ALLERGIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PHARMACY_PAGE_NAME }),
      },
    },
    [PHARMACY_PAGE_NAME]: {
      on: {
        submit: async (getState, searchParams) => {
          const { subscription } = await getState(patientProgressAtom);

          if (!subscription && searchParams.is_new_member !== 'true') {
            return { name: PRICING_EXPLANATION_PAGE_NAME };
          }

          return { name: CONFIRM_TREATMENT_PAGE_NAME };
        },
      },
    },
    [PRICING_EXPLANATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREDIT_CARD_PAGE_NAME }),
        learnMore: () => {
          throw new Error('LearnMore action only available for new member bookings');
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CONFIRM_TREATMENT_PAGE_NAME }),
      },
    },
    [CONFIRM_TREATMENT_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const prescriptionState = await getState(prescriptionAtom);
          const type = prescriptionState.treatment?.type;

          if (!type) {
            throw new Error('Missing type in prescription state when navigating to Next Steps page');
          }

          return { name: NEXT_STEPS_PAGE_NAME, params: { type } };
        },
        cardRequired: (_1, _2, error: string | undefined) => ({
          name: CREDIT_CARD_PAGE_NAME,
          params: { error },
        }),
      },
    },
    [NEXT_STEPS_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
