import type { LocationAttributes } from './location';

export type AddressFormValues = {
  line1: string;
  line2?: string;
  city: string;
  stateCode: string;
  zip: string;
};

export type PharmacyFormValues = {
  pharmacy: LocationAttributes;
};

export type ReferralFormValues = {
  referral: string;
};
