import { useEffect } from 'react';

import { useLocalSearchParams } from 'expo-router';

import { navigationUtilities } from '~modules/navigation/services';
import { useRouteToConfig } from '~modules/routing';

import type { VisitOutcome } from '../visitOutcomes';

export const useVerifyValidVisitReason = (visitOutcome: VisitOutcome | null): visitOutcome is VisitOutcome => {
  // Enforce valid visit reason. If someone navigates to /whatever-page?visit_reason=asdfgh
  // and that page has allowDirectRouting: true, we want to force them back to the Visit Reasons page
  const routeToConfig = useRouteToConfig();
  const searchParams = useLocalSearchParams();

  useEffect(() => {
    if (!visitOutcome) {
      const config = navigationUtilities.getInitialConfig(searchParams.is_new_member !== 'false');

      routeToConfig(config, false, searchParams);
    }
  }, [routeToConfig, searchParams, visitOutcome]);

  return !!visitOutcome;
};
