import type { AppointmentAvailabilityMetadataState, AppointmentParamsState, PrescriptionState } from '~types';

export const APPOINTMENT_AVAILABILITY_METADATA_STATE_KEY = 'appointmentAvailabilityMetadataState';
export const APPOINTMENT_PARAMS_STATE_KEY = 'appointmentParamsState';
export const PRESCRIPTION_STATE_KEY = 'prescriptionState';

export const DEFAULT_APPOINTMENT_AVAILABILITY_METADATA_STATE: AppointmentAvailabilityMetadataState = {};

export const DEFAULT_APPOINTMENT_PARAMS_STATE: AppointmentParamsState = {
  isNewMemberRemote: true,
};

export const DEFAULT_PRESCRIPTION_STATE: PrescriptionState = {
  treatment: null,
  additionalItems: [],
};
