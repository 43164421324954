import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { Label } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { useCreateVaginitisRx } from '~modules/accountSetup/hooks';
import { PAYMENT_FAILED_ERROR_STATUS_CODE, PaymentRequiredError } from '~modules/errors';
import { MainForm } from '~modules/forms';
import { useCreateSubscription } from '~modules/payment';
import { calculateDiscountedTotalPrice } from '~modules/profile/services';
import { useAsync } from '~modules/promises';
import { TREATMENT_RECOMMENDATION_CONFIG } from '~modules/questionnaire/config';
import { useRouteNavigation } from '~modules/routing';
import {
  accountSetupAtom,
  creditCardAtom,
  patientProgressAtom,
  prescriptionAtom,
  questionnaireAtom,
} from '~modules/state';
import { MessageContainer } from '~modules/ui';

import type { CONFIRM_TREATMENT_PAGE_NAME } from '~types';

export const ConfirmTreatmentPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const createSubscription = useCreateSubscription();
  const createVaginitisRx = useCreateVaginitisRx();
  const accountSetupState = useRecoilValue(accountSetupAtom);
  const searchParams = useLocalSearchParams();
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const questionnaireState = useRecoilValue(questionnaireAtom);
  const prescriptionState = useRecoilValue(prescriptionAtom);
  const creditCardState = useRecoilValue(creditCardAtom);
  const { dispatch } = useRouteNavigation<typeof CONFIRM_TREATMENT_PAGE_NAME>();

  const isResubscribe = searchParams.is_new_member !== 'true' && !patientProgressState.subscription;
  const isPrescription = prescriptionState.treatment?.type === 'prescription';

  const resubscribePrice = useMemo(() => {
    if (!isResubscribe || !creditCardState.product) {
      return;
    }

    const discountTotalPrice = calculateDiscountedTotalPrice(creditCardState.product, [
      creditCardState.promotionCode,
      creditCardState.friendReferralCode,
    ]);

    if (discountTotalPrice === creditCardState.product.price.amount) {
      return formatPriceInCents(discountTotalPrice);
    }

    return parseStrikethrough(
      `~${formatPriceInCents(creditCardState.product.price.amount)}~ ${formatPriceInCents(discountTotalPrice)}`
    );
  }, [creditCardState, isResubscribe]);

  const handleSubmit = useCallback(async () => {
    const toCall = async () => {
      if (isResubscribe) {
        try {
          await createSubscription();
        } catch (error) {
          if (error instanceof PaymentRequiredError && error.status === PAYMENT_FAILED_ERROR_STATUS_CODE) {
            return dispatch('cardRequired', error.message);
          }

          throw error;
        }
      }

      await createVaginitisRx();
      dispatch('submit');
    };

    doAsync(toCall);
  }, [createSubscription, createVaginitisRx, dispatch, doAsync, isResubscribe]);

  return (
    <MainForm
      id="confirmTreatment"
      title={t(`confirmTreatment.${isPrescription ? 'prescription' : 'otc'}Title${isResubscribe ? 'Resubscribe' : ''}`)}
      submitButtonTitle={isPrescription ? t('confirmTreatment.submit') : t('confirmTreatment.confirm')}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      submitButtonType="accent"
    >
      <MessageContainer testID="ConfirmTreatmentPage">
        {questionnaireState.diagnosis && (
          <Label title={t('confirmTreatment.labels.treatmentPlan')}>
            {t(
              `treatmentRecommendation.message.${
                TREATMENT_RECOMMENDATION_CONFIG[
                  questionnaireState.diagnosis === 'acute_vaginitis' ? 'bacterialVaginosisRx' : 'yeastRx'
                ].id
              }.title`
            )}
          </Label>
        )}
        {prescriptionState.treatment && (
          <Label title={t('confirmTreatment.labels.treatment')}>
            {prescriptionState.treatment.title}
            {prescriptionState.additionalItems.length
              ? `, ${prescriptionState.additionalItems.map(i => i.title).join(', ')}`
              : ''}
          </Label>
        )}
        <Label title={t('confirmTreatment.labels.payment')}>
          <>
            {isResubscribe ? (
              <>
                {`${t('classicMembershipRenewal')}: `}
                {resubscribePrice}
                {'. '}
              </>
            ) : (
              ''
            )}
            {t('confirmTreatment.included')}
          </>
        </Label>
        {isPrescription && accountSetupState.pharmacy && (
          <Label title={t('confirmTreatment.labels.pharmacy')}>
            {[
              accountSetupState.pharmacy.name,
              accountSetupState.pharmacy.addressLine1,
              accountSetupState.pharmacy.city,
              accountSetupState.pharmacy.region,
            ].join(', ')}
          </Label>
        )}
      </MessageContainer>
    </MainForm>
  );
};
