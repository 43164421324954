import React from 'react';

import { SelectInput, useController, useFormContext } from '@almond/ui';
import { usePathname } from 'expo-router';
import { get } from 'react-hook-form';

import type { SelectInputProps } from '@almond/ui';

export type ConnectedSelectInputProps<T extends string> = Omit<
  SelectInputProps<T>,
  'onSelect' | 'value' | 'index' | 'closeModalTrigger'
> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedSelectInput: React.FC<ConnectedSelectInputProps<string>> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field, formState } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;
  const pathname = usePathname();

  return (
    <SelectInput
      {...restField}
      {...restProps}
      error={get(formState.errors, name)}
      value={value}
      onSelect={selectedValue => onChange(selectedValue)}
      closeModalTrigger={pathname}
    />
  );
};

export default ConnectedSelectInput;
