import { demographicAtom } from '~modules/state';
import {
  CASH_RATES_PAGE_NAME,
  CREATE_PATIENT_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  DEMOGRAPHIC_PAGE_NAME,
  INSURANCE_NOT_ACCEPTED_PAGE_NAME,
  INSURANCE_PAGE_NAME,
  LEARN_MORE_PAGE_NAME,
  NEW_MEMBER_SCHEDULING_PAGE_NAME,
  PHONE_VERIFICATION_PAGE_NAME,
  PRICING_EXPLANATION_PAGE_NAME,
  RECOMMENDATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TELL_US_MORE_PAGE_NAME,
  VISIT_REASONS_PAGE_NAME,
} from '~types';

import { bookingRoutes } from './booking';

import type { RoutingConfig, StackParamList } from '~types';

export const initialNewMemberRoutes = {
  id: 'initialNewMember' as const,
  initial: () => ({ name: VISIT_REASONS_PAGE_NAME }),
  routes: {
    [VISIT_REASONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: RECOMMENDATION_PAGE_NAME }),
      },
    },
    [RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
        curatedSubmit: () => {
          throw new Error(`Recommendation page for new member can't dispatch "curatedSubmit" event`);
        },
        secondary: () => ({ name: SCHEDULING_PAGE_NAME, params: { showAll: 'true' } }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => ({ name: TELL_US_MORE_PAGE_NAME }),
        curatedSubmit: () => {
          throw new Error(`Scheduling page for new member can't dispatch "curatedSubmit" event`);
        },
      },
    },
    [TELL_US_MORE_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DEMOGRAPHIC_PAGE_NAME }),
      },
    },
    [DEMOGRAPHIC_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const demographicState = await getState(demographicAtom);

          if (demographicState.isOptedIntoSms) {
            return { name: PHONE_VERIFICATION_PAGE_NAME };
          }

          return { name: INSURANCE_PAGE_NAME };
        },
      },
    },
    [PHONE_VERIFICATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: INSURANCE_PAGE_NAME }),
      },
    },
    [INSURANCE_PAGE_NAME]: {
      on: {
        submit: (_1, _2, params?: { isAccepted: boolean; hasInsurance: boolean }) => {
          if (!params?.hasInsurance) return { name: CASH_RATES_PAGE_NAME };
          if (params?.isAccepted) return { name: PRICING_EXPLANATION_PAGE_NAME };

          return { name: INSURANCE_NOT_ACCEPTED_PAGE_NAME };
        },
      },
    },
    [INSURANCE_NOT_ACCEPTED_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRICING_EXPLANATION_PAGE_NAME }),
      },
    },
    [CASH_RATES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRICING_EXPLANATION_PAGE_NAME }),
      },
    },
    [PRICING_EXPLANATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREDIT_CARD_PAGE_NAME }),
        learnMore: () => ({ name: LEARN_MORE_PAGE_NAME }),
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREATE_PATIENT_PAGE_NAME }),
      },
    },
    [CREATE_PATIENT_PAGE_NAME]: {
      on: { complete: () => bookingRoutes },
    },
    [LEARN_MORE_PAGE_NAME]: {
      on: {
        submit: () => ({ name: NEW_MEMBER_SCHEDULING_PAGE_NAME }),
      },
    },
    [NEW_MEMBER_SCHEDULING_PAGE_NAME]: {
      on: {},
    },
  },
} satisfies RoutingConfig<StackParamList>;
