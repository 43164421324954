import { I18n } from '@almond/localization';

import type { MedicalConditionsEnum, VaginalInfectionSymptomsEnum } from '@almond/api-types';
import type { CheckboxGroupItem, RadioGroupItem } from '@almond/ui';
import type { BinaryEnum, TestingEnum, TreatmentRecommendationConfig, TreatmentRecommendationId } from '~types';

export const BINARY_ITEMS: RadioGroupItem<BinaryEnum>[] = [
  { id: 'yes', title: I18n.t('yes') },
  { id: 'no', title: I18n.t('no') },
];

export const MEDICAL_CONDITIONS_ITEMS: CheckboxGroupItem<MedicalConditionsEnum>[] = [
  { id: 'diabetes', title: I18n.t('medicalConditions.diabetes') },
  { id: 'liver_disease', title: I18n.t('medicalConditions.liverDisease') },
  { id: 'seizures', title: I18n.t('medicalConditions.seizures') },
  { id: 'cardiac_disorder', title: I18n.t('medicalConditions.cardiacDisorder') },
  { id: 'immunosuppressive_condition', title: I18n.t('medicalConditions.immunosuppressiveCondition') },
  { id: 'hidradendritus_suppurativa', title: I18n.t('medicalConditions.hidradendritusSuppurativa') },
  { id: 'none_of_these', title: I18n.t('noneOfThese') },
];

export const TESTING_ITEMS: (RadioGroupItem<TestingEnum> & { description: string })[] = [
  {
    id: 'self_serve_testing',
    title: I18n.t('testing.items.self_serve_testing.title'),
    description: I18n.t('testing.items.self_serve_testing.description'),
  },
];

export const TREATMENT_RECOMMENDATION_CONFIG: Record<TreatmentRecommendationId, TreatmentRecommendationConfig> = {
  mdVisit: {
    id: 'mdVisit',
    outcome: 'visit',
    secondaryTitle: I18n.t('bookSomethingElse'),
  },
  npVisit: {
    id: 'npVisit',
    outcome: 'visit',
    secondaryTitle: I18n.t('bookSomethingElse'),
  },
  testing: {
    id: 'testing',
    outcome: 'testing',
    secondaryTitle: I18n.t('bookSomethingElse'),
  },
  yeastRx: {
    id: 'yeastRx',
    outcome: 'rx',
    linkTitle: I18n.t('treatmentRecommendation.message.yeastRx.linkTitle'),
    link: 'https://www.healthline.com/health/vaginal-yeast-infection',
    secondaryTitle: I18n.t('getTestedInstead'),
  },
  bacterialVaginosisRx: {
    id: 'bacterialVaginosisRx',
    outcome: 'rx',
    linkTitle: I18n.t('treatmentRecommendation.message.bacterialVaginosisRx.linkTitle'),
    link: 'https://www.healthline.com/health/bacterial-vaginosis',
    secondaryTitle: I18n.t('getTestedInstead'),
  },
  skinSymptoms: {
    id: 'skinSymptoms',
    secondaryTitle: I18n.t('bookSomethingElse'),
  },
  noSkinSymptoms: {
    id: 'noSkinSymptoms',
    secondaryTitle: I18n.t('bookSomethingElse'),
  },
};

export const VAGINAL_INFECTION_SYMPTOMS_ITEMS: CheckboxGroupItem<VaginalInfectionSymptomsEnum>[] = [
  { id: 'gray_green_discharge', title: I18n.t('vaginalInfectionSymptoms.grayGreenDischarge') },
  { id: 'cottage_cheese_discharge', title: I18n.t('vaginalInfectionSymptoms.cottageCheeseDischarge') },
  { id: 'vaginal_itching', title: I18n.t('vaginalInfectionSymptoms.vaginalItching') },
  { id: 'fishy_odor', title: I18n.t('vaginalInfectionSymptoms.fishyOdor') },
  { id: 'something_else', title: I18n.t('somethingElse') },
];
