import { I18n } from '@almond/localization';

import type { DiagnosisCodesEnum } from '@almond/api-types';
import type { CheckboxGroupItem } from '@almond/ui';
import type { MainSymptomEnum, TreatmentOption } from '~types';

export const MAX_CALENDAR_BAR_DAYS_VISIBLE = 11;

export const MAIN_SYMPTOMS_ITEMS: CheckboxGroupItem<MainSymptomEnum>[] = [
  { id: 'ulb', title: I18n.t('mainSymptoms.ulb') },
  { id: 'somethingElse', title: I18n.t('somethingElse') },
  { id: 'noneOfThese', title: I18n.t('noneOfThese') },
];

export const TREATMENT_ITEMS = {
  yeast_infection: {
    options: [
      {
        id: 'fluconazole_rx',
        type: 'prescription',
        title: I18n.t('treatment.items.fluconazole_rx.title'),
        description: I18n.t('treatment.items.fluconazole_rx.description'),
        items: I18n.t('treatment.items.fluconazole_rx.items', { returnObjects: true }),
        link: I18n.t('treatment.items.fluconazole_rx.link'),
        href: 'https://www.healthline.com/health/drugs/fluconazole-cost',
        details: {
          medication: 'fluconazole',
          directions: I18n.t('treatment.items.fluconazole_rx.directions'),
          qty: '1',
          authRefills: 0,
          qtyUnits: 'CAPSULE',
        },
      },
      {
        id: 'monistat_rx',
        type: 'otc',
        title: I18n.t('treatment.items.monistat_rx.title'),
        description: I18n.t('treatment.items.monistat_rx.description'),
        items: I18n.t('treatment.items.monistat_rx.items', { returnObjects: true }),
        link: I18n.t('treatment.items.monistat_rx.link'),
        href: 'https://www.healthline.com/health/womens-health/monistat-reviews',
        details: {
          medication: 'three_day_monistat',
          directions: I18n.t('treatment.items.monistat_rx.directions'),
          qty: '3',
          authRefills: 0,
        },
      },
    ],
    additional: [
      {
        id: 'vaginal_probiotic',
        type: 'otc',
        title: I18n.t('treatment.items.vaginal_probiotic.title'),
        description: I18n.t('treatment.items.vaginal_probiotic.description'),
        link: I18n.t('treatment.items.vaginal_probiotic.link'),
        href: 'https://biomprobiotics.com/product/vaginal-probiotic-suppository-natural/',
      },
    ],
  },
  acute_vaginitis: {
    options: [
      {
        id: 'oral_metronidazole',
        type: 'prescription',
        title: I18n.t('treatment.items.oral_metronidazole.title'),
        description: I18n.t('treatment.items.oral_metronidazole.description'),
        items: I18n.t('treatment.items.oral_metronidazole.items', { returnObjects: true }),
        link: I18n.t('treatment.items.oral_metronidazole.link'),
        href: 'https://www.healthline.com/health/infection/metronidazole-side-effects',
        details: {
          medication: 'oral_metrodianazole',
          directions: I18n.t('treatment.items.oral_metronidazole.directions'),
          qty: '14',
          qtyUnits: 'CAPSULE',
          authRefills: 0,
        },
      },
      {
        id: 'topical_metronidazole',
        type: 'prescription',
        title: I18n.t('treatment.items.topical_metronidazole.title'),
        description: I18n.t('treatment.items.topical_metronidazole.description'),
        items: I18n.t('treatment.items.topical_metronidazole.items', { returnObjects: true }),
        link: I18n.t('treatment.items.topical_metronidazole.link'),
        href: 'https://www.healthline.com/health/infection/metronidazole-side-effects',
        details: {
          medication: 'topical_metronidazole',
          directions: I18n.t('treatment.items.topical_metronidazole.directions'),
          qty: '25',
          authRefills: 0,
          qtyUnits: 'GRAM',
        },
      },
    ],
    additional: [
      {
        id: 'vaginal_probiotic',
        type: 'otc',
        title: I18n.t('treatment.items.vaginal_probiotic.title'),
        description: I18n.t('treatment.items.vaginal_probiotic.description'),
        link: I18n.t('treatment.items.vaginal_probiotic.link'),
        href: 'https://biomprobiotics.com/product/vaginal-probiotic-suppository-natural/',
      },
    ],
  },
} as {
  [key in DiagnosisCodesEnum]: {
    options: TreatmentOption[];
    additional?: TreatmentOption[];
  };
};
