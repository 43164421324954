import { useEffect } from 'react';

import { useLocalSearchParams } from 'expo-router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { useAuth0 } from '~modules/auth/hooks';
import { HttpRequestError } from '~modules/errors';
import { creditCardAtom, patientProgressAtom } from '~modules/state';

import type { PromotionCodeType } from '@almond/api-types';
import type { PromotionCode } from '~types';

export const useCheckPromotionCode = (
  key: 'promotionCode' | 'friendReferralCode',
  promotionCodeType: PromotionCodeType
) => {
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const searchParams = useLocalSearchParams();
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();

  useEffect(() => {
    if (isAuthenticating || searchParams.is_new_member === 'false') {
      return;
    }

    if (key === 'promotionCode') {
      if (isAuthenticated) {
        // Waiting for the patient to be loaded if the current patient is an established one.
        if (patientProgressState.subscription == null) {
          return;
        }

        // Skipping checking the promotion code if the current patient already has a subscription.
        if (patientProgressState.subscription) {
          return;
        }
      }
    }

    const promotionCode = creditCardState[key];

    if (promotionCode?.state !== 'init') {
      return;
    }

    const toCall = async () => {
      try {
        const result = await profilesApi.checkPromotionCode(promotionCode.code);

        if (result.promotionCodeType !== promotionCodeType) {
          throw new HttpRequestError(404);
        }

        setCreditCardState(prevState => ({ ...prevState, [key]: { ...result, state: 'success' } }));
      } catch (e) {
        if (e instanceof HttpRequestError && e.status === 404) {
          const resultWithTag: PromotionCode = { ...promotionCode, state: 'not-found' };

          setCreditCardState(prevState => ({ ...prevState, [key]: resultWithTag }));
        }

        // Swallow the error - the initial page doesn't need to worry about displaying any errors.
        // If the request fails, we'll arrive at the CreditCard page without a valid referral code lookup,
        // but then the CreditCard page will perform a lookup and show any potential error codes there.
      }
    };

    toCall();
  }, [
    creditCardState,
    isAuthenticated,
    isAuthenticating,
    key,
    patientProgressState.subscription,
    promotionCodeType,
    searchParams.is_new_member,
    setCreditCardState,
  ]);
};
