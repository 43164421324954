import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { CheckboxGroup, MainForm, RadioGroup } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useSaveQuestionnaire } from '~modules/questionnaire/hooks';
import { useRouteNavigation } from '~modules/routing';
import { prescriptionAtom, questionnaireAtom } from '~modules/state';

import { TREATMENT_ITEMS } from '../../config';
import Item from './Item';

import themedStyles from './styles';

import type { PrescriptionState, TREATMENT_PAGE_NAME, TreatmentParams } from '~types';

export const TreatmentPage: React.FC = () => {
  const searchParams = useLocalSearchParams<TreatmentParams>();
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const saveQuestionnaire = useSaveQuestionnaire();
  const setQuestionnaireState = useSetRecoilState(questionnaireAtom);
  const { dispatch } = useRouteNavigation<typeof TREATMENT_PAGE_NAME>();
  const { options, additional } = TREATMENT_ITEMS[searchParams.key];

  const handleSubmit = useCallback(
    (values: PrescriptionState) => {
      const toCall = async () => {
        if (!values.treatment) return;

        const { type } = values.treatment;
        const treatmentPreference = [values.treatment.id, ...values.additionalItems.map(item => item.id)];

        setQuestionnaireState(prevState => ({ ...prevState, treatmentPreference }));

        if (type === 'prescription') {
          await saveQuestionnaire({ treatmentPreference }, 'treatment_preference', 'treatmentPreference');
        }

        await dispatch('submit', type);
      };

      doAsync(toCall);
    },
    [dispatch, doAsync, saveQuestionnaire, setQuestionnaireState]
  );

  return (
    <MainForm
      id="treatment"
      atom={prescriptionAtom}
      title={t('treatment.title')}
      size="M"
      isLoading={isLoading}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      requiredFields={['treatment']}
    >
      <RadioGroup name="treatment" items={options} RadioButtonComponent={Item} title={t('treatment.optionPrompt')} />
      {additional && (
        <>
          <Text style={styles.checkboxGroupTitle} fontStyle="bold" size="xl">
            {t('treatment.additionalPrompt')}
          </Text>
          <CheckboxGroup name="additionalItems" items={additional} CheckboxItemComponent={Item} hideDefaultTitle />
        </>
      )}
    </MainForm>
  );
};
