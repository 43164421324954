import type {
  AvailableSlot,
  ProviderTypesEnum,
  QuestionnaireSourcesEnum,
  VisitLocationEnum,
  VisitReasonOut,
} from '@almond/api-types';
import type { AppointmentParamsState } from '~types';

export const curatedFlow = (visitReason?: string): QuestionnaireSourcesEnum => {
  if (visitReason === 'sti_check') return 'sti';
  if (visitReason === 'infection') return 'vaginal_infection';

  return 'demi_classic';
};

export const isCuratedFlowLegacy = (state: AppointmentParamsState, visitReason?: string) => {
  return !state.isNewMemberRemote && visitReason && ['sti_check', 'infection'].includes(visitReason);
};

export const isCuratedFlow = (state: AppointmentParamsState, visitReason?: string) => {
  return !state.isNewMemberRemote && visitReason === 'general_infection';
};

export const isMaternityVisitReason = (visitReasons: readonly VisitReasonOut[], visitReason?: string) => {
  return visitReasons.find(vr => vr.code === visitReason)?.category === 'maternity';
};

export const sortPhysicians = <
  T extends {
    physician: { providerType: ProviderTypesEnum };
    appointmentTypeDetails: { location: VisitLocationEnum };
    timeSlots: readonly AvailableSlot[];
    recommendationType: 'primary' | 'secondary';
  },
>(
  physicians: readonly T[]
) => {
  const providerOrder: ProviderTypesEnum[][] = [['HC', 'MA'], ['NP', 'PA'], ['MD']];
  const locationOrder: VisitLocationEnum[] = ['Telehealth', 'Office'];

  return Array.from(physicians).sort((a, b) => {
    const aHasAvailability = a.timeSlots.length > 0 ? 1 : 0;
    const bHasAvailability = b.timeSlots.length > 0 ? 1 : 0;

    if (aHasAvailability !== bHasAvailability) {
      return bHasAvailability - aHasAvailability;
    }

    const aIsPrimary = a.recommendationType === 'primary' ? 0 : 1;
    const bIsPrimary = b.recommendationType === 'primary' ? 0 : 1;

    if (aIsPrimary !== bIsPrimary) {
      return aIsPrimary - bIsPrimary;
    }

    const aProviderType = a.physician.providerType;
    const bProviderType = b.physician.providerType;

    const aProviderIndex = providerOrder.findIndex(providers => providers.includes(aProviderType));
    const bProviderIndex = providerOrder.findIndex(providers => providers.includes(bProviderType));

    if (aProviderIndex !== bProviderIndex) {
      return aProviderIndex - bProviderIndex;
    }

    const aLocationIndex = locationOrder.indexOf(a.appointmentTypeDetails.location);
    const bLocationIndex = locationOrder.indexOf(b.appointmentTypeDetails.location);

    return aLocationIndex - bLocationIndex;
  });
};
