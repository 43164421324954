import { useRef } from 'react';

import { shouldLogAnalytics, trackShareASaleTransaction, useTrackAnalyticsEvent } from '@almond/analytics';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import env from '~env';
import { patientsApi } from '~modules/api';
import { calculateDiscountedTotalPrice } from '~modules/profile/services';
import { creditCardAtom, patientProgressAtom, profileProgressAtom, userAtom } from '~modules/state';
import { userUtilities } from '~modules/user';

import { log, logAndCapture } from '../logger';
import { useConfirmPayment } from './useConfirmPayment';

const useCreateSubscription = () => {
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const confirmPayment = useConfirmPayment();
  const searchParams = useLocalSearchParams();

  const secretRef = useRef<Record<string, { clientSecret?: string; type?: string }>>({});

  return useRecoilCallback(
    // eslint-disable-next-line max-statements
    recoilInterface => async (paymentMethod?: string) => {
      const profileProgressState = await recoilInterface.snapshot.getPromise(profileProgressAtom);
      const userState = await recoilInterface.snapshot.getPromise(userAtom);
      const creditCardState = await recoilInterface.snapshot.getPromise(creditCardAtom);

      const isAdmin = userUtilities.isAdmin(userState.user);

      if (!searchParams.patient_uuid) {
        logAndCapture("Can't create subscription without patient_uuid");

        return null;
      }

      if (!creditCardState.product) {
        logAndCapture("Can't create subscription without product");

        return null;
      }

      if (!profileProgressState?.creditCard) {
        if (isAdmin) {
          log('Admin user - skipping subscription creation since no credit card has been entered');

          return null;
        }
      }

      log('Creating subscription for the patient');

      const subscription = await patientsApi.createSubscription({
        promotionCode:
          creditCardState.promotionCode?.state === 'success' ? creditCardState.promotionCode.id : undefined,
        friendReferralCode:
          creditCardState.friendReferralCode?.state === 'success' ? creditCardState.friendReferralCode.id : undefined,
        price: creditCardState.product?.price.id,
        paymentBehavior: 'default_incomplete',
      });

      if (!subscription.stripeSubscriptionId) {
        logAndCapture('Subscription ID was not created despite a successful subscription request to the API');

        return null;
      }

      const value = secretRef.current[subscription.stripeSubscriptionId] || {};

      secretRef.current[subscription.stripeSubscriptionId] = value;

      value.clientSecret = subscription.clientSecret ?? value.clientSecret;
      value.type = subscription.type ?? value.type;

      await confirmPayment(value.type, value.clientSecret, paymentMethod);

      if (shouldLogAnalytics() && creditCardState.product) {
        const discountTotalPrice = calculateDiscountedTotalPrice(creditCardState.product, [
          creditCardState.promotionCode,
          creditCardState.friendReferralCode,
        ]);

        trackShareASaleTransaction(subscription.stripeSubscriptionId, discountTotalPrice, env.MERCHANT_ID);
      }

      trackAnalyticsEvent('membership_purchased', {
        isNewMember: searchParams.is_new_member === 'true',
        patientUuid: searchParams.patient_uuid,
      });

      setPatientProgressState(prevState => ({ ...prevState, subscription: true }));

      return subscription.stripeSubscriptionId;
    },
    [
      confirmPayment,
      searchParams.is_new_member,
      searchParams.patient_uuid,
      setPatientProgressState,
      trackAnalyticsEvent,
    ]
  );
};

export default useCreateSubscription;
