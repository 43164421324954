import * as axiosBase from '../axiosBase';
import { AXIOS_LONG_REQUEST_TIMEOUT } from '../config';
import {
  APPOINTMENT_PATH,
  APPOINTMENTS_PATH,
  BOOKING_PATH,
  LAB_SUBMIT_PATH,
  PAST_COUNT_PATH,
  PRESCRIPTION_REQUESTS_PATH,
} from './config';

export const createAppointment = axiosBase.post(APPOINTMENTS_PATH);

export const createAppointmentBooking = (appointmentUuid: string) =>
  axiosBase.post(BOOKING_PATH(appointmentUuid), { timeout: 16000 })();

export const getAppointment = (appointmentUuid: string) => axiosBase.get(APPOINTMENT_PATH(appointmentUuid));

export const submitLabOrders = (appointmentUuid: string) => axiosBase.post(LAB_SUBMIT_PATH(appointmentUuid));

export const getPastAppointmentCount = (patientUuid: string) => axiosBase.get(PAST_COUNT_PATH(patientUuid));

export const createPrescriptionRequest = axiosBase.post(PRESCRIPTION_REQUESTS_PATH, {
  timeout: AXIOS_LONG_REQUEST_TIMEOUT,
});
