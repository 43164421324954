import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { appointmentParamsAtom } from '~modules/state';

import { getProviderTypeString } from '../services';
import { useRecommendationBodyText } from './useRecommendationBodyText';
import { useVisitOutcome } from './useVisitOutcome';
import { useVisitReasons } from './useVisitReasons';

export const useSchedulingVisitReasonsModal = () => {
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const searchParams = useLocalSearchParams();
  const isNewMember = appointmentParamsState.isNewMemberRemote;
  const visitReason = searchParams.visit_reason;
  const { visitOutcome } = useVisitOutcome();
  const modalBody = useRecommendationBodyText(visitOutcome);
  const { visitReasons, isLoadingVisitReasons, visitReasonsError } = useVisitReasons();

  if (!visitReason || !visitOutcome || isLoadingVisitReasons || !visitReasons || visitReasonsError) {
    return { visitOutcome, isNewMember, visitReason };
  }

  const visitReasonTitle = visitReasons.find(vr => vr.code === visitReason)?.title;
  const providerTitleText = visitOutcome?.providerTypes ? getProviderTypeString(visitOutcome.providerTypes) : '';

  const text = {
    modalBody,
    visitReasonTitle,
    providerTitleText,
  };

  return {
    text,
    visitOutcome,
    isNewMember,
    visitReason,
  };
};
