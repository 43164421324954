/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';

import add from './materialIcons/add.svg';
import addAPhoto from './materialIcons/add-a-photo.svg';
import arrowBack from './materialIcons/arrow-back.svg';
import arrowCircleUp from './materialIcons/arrow-circle-up.svg';
import arrowUpward from './materialIcons/arrow-upward.svg';
import attachFile from './materialIcons/attach-file.svg';
import check from './materialIcons/check.svg';
import close from './materialIcons/close.svg';
import contentCopy from './materialIcons/content-copy.svg';
import del from './materialIcons/delete.svg';
import doneAll from './materialIcons/done-all.svg';
import download2 from './materialIcons/download-2.svg';
import error from './materialIcons/error.svg';
import info from './materialIcons/info.svg';
import keep from './materialIcons/keep.svg';
import keyboardArrowDown from './materialIcons/keyboard-arrow-down.svg';
import keyboardArrowUp from './materialIcons/keyboard-arrow-up.svg';
import markChatUnread from './materialIcons/mark-chat-unread.svg';
import mood from './materialIcons/mood.svg';
import moreVert from './materialIcons/more-vert.svg';
import progressActivity from './materialIcons/progress-activity.svg';
import refresh from './materialIcons/refresh.svg';
import replyAll from './materialIcons/reply-all.svg';
import search from './materialIcons/search.svg';
import upload2 from './materialIcons/upload-2.svg';
import egg from './visitReasons/egg.svg';
import femaleDoctor from './visitReasons/femaleDoctor.svg';
import fire from './visitReasons/fire.svg';
import fish from './visitReasons/fish.svg';
import heart from './visitReasons/heart.svg';
import lock from './visitReasons/lock.svg';
import microscope from './visitReasons/microscope.svg';
import period from './visitReasons/period.svg';
import skin from './visitReasons/skin.svg';
import stethescope from './visitReasons/stethescope.svg';
import tablet from './visitReasons/tablet.svg';
import tablet2 from './visitReasons/tablet2.svg';

export const materialIcons = {
  add,
  'add-a-photo': addAPhoto,
  'arrow-back': arrowBack,
  'arrow-circle-up': arrowCircleUp,
  'arrow-upward': arrowUpward,
  'attach-file': attachFile,
  check,
  close,
  'content-copy': contentCopy,
  delete: del,
  'done-all': doneAll,
  'download-2': download2,
  error,
  info,
  keep,
  'keyboard-arrow-down': keyboardArrowDown,
  'keyboard-arrow-up': keyboardArrowUp,
  'mark-chat-unread': markChatUnread,
  mood,
  'more-vert': moreVert,
  'progress-activity': progressActivity,
  refresh,
  'reply-all': replyAll,
  search,
  'upload-2': upload2,
};

const visitReasonIcons = {
  birth_control: lock,
  first_maternity: heart,
  infection: fish,
  general_infection: stethescope,
  hormonal_health_or_fertility: tablet2,
  maternity_follow_up: heart,
  other: stethescope,
  period_management: period,
  pregnancy_confirmation: heart,
  pregnancy_intro_call: tablet,
  pregnancy_preconception: egg,
  pregnancy_termination: tablet,
  early_pregnancy_counseling: tablet,
  sti_check: microscope,
  yearly_exam: tablet2,
  vulvar_skin_symptoms: skin,
  perimenopause_or_menopause: fire,
  assisted_lab: microscope,
  procedure: femaleDoctor,
};

export const getVisitReasonIcon = (visitReason: string) => {
  return visitReasonIcons[visitReason as keyof typeof visitReasonIcons] ?? tablet;
};

type VisitReasonIconProps = {
  width?: number;
  height?: number;
  visitReason: string;
};

export const VisitReasonIcon = (props: VisitReasonIconProps) => {
  const { visitReason, ...restProps } = props;
  const Icon = getVisitReasonIcon(visitReason);

  return <Icon {...restProps} />;
};

export { default as AlmondLogo } from './almondLogo.svg';
export { default as Calendar } from './calendar.svg';
export { default as Camera } from './camera.svg';
export { default as ChevronRight } from './chevronRight.svg';
export { default as Phone } from './phone.svg';
export { default as Stars } from './stars.svg';
export const LabOrderIcon = tablet2;
