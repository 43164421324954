import React, { useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useLocalSearchParams } from 'expo-router';

import { feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';

import { useRedirectBackToArtemis } from '../../hooks';
import { AbandonModal } from '../AbandonModal';
import { ConfirmationModal } from '../ConfirmationModal';
import { FeedbackModal } from '../FeedbackModal';

export type AbandonModalWrapperProps = {
  isVisible: boolean;
  onRequestClose: () => void;
  onLeave: () => void;
};

export const AbandonModalWrapper = (props: AbandonModalWrapperProps) => {
  const { isVisible, onRequestClose, onLeave } = props;
  const [activeModal, setActiveModal] = useState<'abandon' | 'feedback' | 'confirmation'>('abandon');
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync } = useAsync();
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const { t } = useTranslation();
  const searchParams = useLocalSearchParams();

  if (!isVisible) return null;

  const handlePress = (key: string, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (searchParams.is_new_member !== 'true') {
        patientResponse.patientUuid = searchParams.patient_uuid;
      }

      const response = await feedbackApi.createFeedback()({
        eventType: 'abandon',
        response: patientResponse,
      });

      setFeedbackUuid(response.uuid);

      if (key === 'somethingElse') {
        setActiveModal('feedback');
      } else if (key === 'contactUs') {
        onRequestClose();
        redirectBackToArtemis('/messages');
      } else {
        setActiveModal('confirmation');
      }
    };

    doAsync(toCall);
  };

  const handleRequestClose = () => {
    setActiveModal('abandon');
    onRequestClose();
  };

  return (
    <>
      <AbandonModal isVisible={activeModal === 'abandon'} onRequestClose={handleRequestClose} onPress={handlePress} />
      {feedbackUuid && (
        <FeedbackModal
          isVisible={activeModal === 'feedback'}
          onRequestClose={handleRequestClose}
          feedbackUuid={feedbackUuid}
          onSubmit={() => setActiveModal('confirmation')}
          eventType="abandon"
          onBack={() => setActiveModal('abandon')}
        />
      )}
      <ConfirmationModal
        isVisible={activeModal === 'confirmation'}
        onRequestClose={handleRequestClose}
        onPress={() => {
          setActiveModal('abandon');
          onLeave();
        }}
        submitTitle={t(searchParams.is_new_member === 'true' ? 'backToWebsite' : 'backToDashboard')}
      />
    </>
  );
};
