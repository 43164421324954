import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';
import { useGlobalSearchParams } from 'expo-router';

import { BaseModal } from '../BaseModal';
import { ModalButton } from '../ModalButton';

import { themedStyles } from './styles';

import type { BaseModalProps } from '../BaseModal';

export type IdleModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  onPress: (key: string, text: string) => void;
};

const newMemberOptions = [
  { key: 'noProviders' as const, text: 'idleModal.newMemberOptions.noProviders' as const },
  { key: 'noDays' as const, text: 'idleModal.newMemberOptions.noDays' as const },
  { key: 'notSure' as const, text: 'idleModal.newMemberOptions.notSure' as const },
  { key: 'notReady' as const, text: 'idleModal.newMemberOptions.notReady' as const },
  { key: 'somethingElse' as const, text: 'idleModal.newMemberOptions.somethingElse' as const },
  { key: 'contactUs' as const, text: 'idleModal.newMemberOptions.contactUs' as const },
];

const establishedMemberOptions = [
  { key: 'noProviders' as const, text: 'idleModal.establishedMemberOptions.noProviders' as const },
  { key: 'noDays' as const, text: 'idleModal.establishedMemberOptions.noDays' as const },
  { key: 'notReady' as const, text: 'idleModal.establishedMemberOptions.notReady' as const },
  { key: 'somethingElse' as const, text: 'idleModal.establishedMemberOptions.somethingElse' as const },
  { key: 'contactUs' as const, text: 'idleModal.establishedMemberOptions.contactUs' as const },
];

export const IdleModal = (props: IdleModalProps) => {
  const { onPress, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const searchParams = useGlobalSearchParams();

  return (
    <BaseModal {...restProps} title={t('idleModal.title')} subtitle={t('idleModal.subtitle')}>
      <View style={styles.container}>
        {(searchParams.is_new_member === 'true' ? newMemberOptions : establishedMemberOptions).map((option, index) => (
          <ModalButton
            key={index}
            onPress={() => onPress(option.key, t(option.text))}
            testID={`IdleModal-${option.key}`}
          >
            {t(option.text)}
          </ModalButton>
        ))}
      </View>
    </BaseModal>
  );
};
