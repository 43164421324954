import type { PerniciousHabitEnum } from '../api';
import type { PregnancyOutcomeEnum } from '@almond/api-types';
import type { SelectInputValue } from '@almond/ui';

export type HistoryState = Partial<{
  healthConditions: string;
  medications: string;
  drugAllergies: string;
  allergies: string;
  surgeries: string;
  pregnancies: SelectInputValue<PregnancyOutcomeEnum>[];
  hospitalization: string;
  familyHistory: string;
  needHealthRecords: boolean;
  doctorInfo: string;
  occupation: string;
  perniciousHabits: SelectInputValue<PerniciousHabitEnum>[];
}>;
